import api from "../../api";
import auth from "../../firebase/auth";
import { createSuccess, createError } from "../../libs/flash-messages";

import { newOrderEmail } from "../emails/newOrder";
import { bulkOrderEmail } from "../emails/bulkOrder";

const state = {
   activeTicket: null
};

const getters = {
   getActiveTicket(state) {
      return state.activeTicket
   }
};

const actions = {
   async getTicket({ commit }, number) {
      try {
         await auth.refreshFlow(auth.provider);
         const res = await api.axiosGet(`/tickets/${number}`);
         commit("COMPLETE");
         return Promise.resolve(res);
      } catch (err) {
         return Promise.reject(err);
      }
   },
   async createOrderTicket({ commit }, data) {
      try {
         await auth.refreshFlow(auth.provider);
         const body = {
            subject: `WOW Request for ${data.active.shippingName} from ${data.active.requesterName}`,
            text: newOrderEmail(data.active, data.form, data.showRespaIssue),
            category: 19,
            name: data.active.requesterName,
            email: data.active.requesterEmail,
            email2: data.active.requester2Email,
            status: 1
         };
         const res = await api.axiosPost("/tickets", body);
         commit("COMPLETE");
         return Promise.resolve(res.id);
      } catch (err) {
         return Promise.reject(err);
      }
   },
   async createBulkTicket({ commit }, data) {
      try {
         await auth.refreshFlow(auth.provider);
         const body = {
            subject: `WOW Request for ${data.order.shippingName} from ${data.order.requesterName}: BULK`,
            text: bulkOrderEmail(data.order, data.active, data.showRespaIssue),
            category: 19,
            name: data.active.requesterName,
            email: data.active.requesterEmail,
            email2: data.active.requester2Email
         };
         const res = await api.axiosPost("/tickets", body);
         commit("COMPLETE");
         return Promise.resolve(res.id);
      } catch (err) {
         createError("Couldn't submit bulk order", err);
         return Promise.reject(err);
      }
   },
   async createContactTicket({ commit }, form) {
      const body = {
         subject: `New Contact from wow.fbhl.com:  ${form.topic}`,
         text: `
            <h2><b>${form.subject}</b></h2>
            <div>${form.message}</div>
         `,
         category: 19,
         name: form.name,
         email: form.email,
         email2: form.email2
      };
      try {
         await auth.refreshFlow(auth.provider);
         await api.axiosPost("/tickets", body);
         createSuccess("Message Sent", "A wow rep will respond as soon as possible");
         commit("COMPLETE");
         return Promise.resolve("success");
      } catch (err) {
         createError("Message failed", err);
         return Promise.reject(err);
      }
   },
   async updateTicket({ commit }, form) {
      try {
         const body = {
            assignee: form.shouldAssign ? form.hfUserId : null,
            notify_user: 1,
            staff: form.hfUserId,
            status: form.status,
            text: form.response
         };
         if (!form.shouldAssign) {
            delete body.assignee;
         }
         const res = await api.axiosPut(`/tickets/${form.id}`, body);
         createSuccess("Response submitted");
         commit("COMPLETE");
         return Promise.resolve(res);
      } catch (err) {
         createError("Couldn't set response", err);
         return Promise.reject(err);
      }
   }
};

const mutations = {
   COMPLETE() {
      return
   },
   SET_ACTIVE_TICKET(state, ticket) {
      state.activeTicket = ticket
   }
};

export default {
   state,
   getters,
   actions,
   mutations
}
