import Vue from 'vue';
import socketio from 'socket.io-client';
import VueSocketIO from 'vue-socket.io';
import store from "../store";

const socket = process.env.NODE_ENV === 'development' ? socketio('http://localhost:3001') : socketio('https://wowapi.fbhl.com');

Vue.use(
   new VueSocketIO({
      connection: socket,
      vuex: {
         store,
         actionPrefix: 'SOCKET_',
         mutationPrefix: 'SOCKET_'
      }
   })
)

export default socket;