<template>
  <section>
    <div class="canned-toggle pointer flex__center" @click="setIsToggled">
      <span class="text__powder text__sm">Your Messages</span>
    </div>
    <div v-if="isToggled" class="modal flex__center">
      <div class="canned-view pos__rel">
        <exit-button @toggleModal="setIsToggled" />
        <!-- <p class="text__bold text__md text__center">Common Messages</p> -->
        <div class="search--bar">
          <input type="text" v-model="search" />
          <div class="btn__search">
            <font-awesome-icon icon="search" size="lg" />
          </div>
        </div>
        <simplebar class="common-overflow">
          <ul class="canned-view--list">
            <li v-if="displayResponses.length <= 0">no messages found</li>
            <li
              v-else
              v-for="res in displayResponses"
              :key="res._id"
              class="pointer"
              @click="emitSetMessage(res)"
            >
              <p class="flex__between">
                <span class="text__bold text__overflow mr-10">{{ res.name }}</span>
                <span class="text__peacock text__bold">Select</span>
              </p>
              <span
                class="text__sm"
              >{{ res.message.slice(0, 40)}}{{ res.message.length > 40 ? "..." : "" }}</span>
            </li>
          </ul>
        </simplebar>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      isToggled: false,
      search: ""
    };
  },
  computed: {
    ...mapGetters(["getUserDetails"]),
    displayResponses() {
      if (!this.search) return this.getUserDetails.responses;
      return this.getUserDetails.responses.filter(res => {
        return res.name.toLowerCase().includes(this.search.toLowerCase());
      });
    }
  },
  methods: {
    setIsToggled() {
      this.isToggled = !this.isToggled;
    },
    emitSetMessage(res) {
      this.$emit("setMessage", res);
      this.setIsToggled();
    }
  }
};
</script>

<style scoped lang="scss">
.canned-toggle {
  @include pos-abs($top: 100%, $left: null, $right: 0, $width: 150px);
  padding: 5px 10px;
  padding-left: 12px;
  background-color: $peacock;
  clip-path: polygon(0 0, 100% 0%, 100% 100%, 9% 100%);
}
.canned-view {
  width: 92%;
  max-width: 325px;
  background-color: lighten($sky, 30%);
  border-radius: 5px;
  padding: 20px;
  margin: auto;
  @include grid($cols: 1fr, $row-gap: 15px);
}
.common-overflow {
  height: 215px;
  padding: 5px 0;
}
.canned-view--list {
  @include grid($cols: 1fr, $row-gap: 8px);
  li {
    @include grid($cols: 1fr, $row-gap: 3px);
    background-color: lighten($sky, 20%);
    padding: 3px;
    border-radius: 3px;
  }
}
</style>