import store from "../store";
import { checkBulkAccess } from "./libs/helpers";

const loadView = view => {
   return () => import(/* webpackChunkName: "set-bulk" */ `../components/Pages/${view}/Index.vue`)
}

export default [
   {
      path: "/batch-order/order-details",
      name: "batch-details",
      component: loadView("BatchDetails"),
      beforeEnter: async (to, from, next) => {
         await checkBulkAccess(to, from, next);
         next();
      }
   },
   {
      path: "/batch-order/:bulk/batch-items",
      name: "batch-items",
      component: loadView("BatchOrders"),
      beforeEnter: async (to, from, next) => {
         await checkBulkAccess(to, from, next);
         try {
            await store.dispatch("startUnsetActiveOrder", null);
            await store.dispatch("startSetActiveBulkOrder", to.params.bulk);
            await store.dispatch("startSetBulkItems", to.params.bulk);
            next()
         } catch (err) {
            return next(from.fullPath);
         }
      }
   },
   {
      path: "/batch-order/:bulk/batch-items/new",
      name: "batch-items-new",
      component: loadView("NewBatchItem"),
      beforeEnter: async (to, from, next) => {
         await checkBulkAccess(to, from, next);
         try {
            await store.dispatch("startSetActiveBulkOrder", to.params.bulk);
            next()
         } catch (err) {
            return next(from.fullPath);
         }
      }
   }
]