import Vue from 'vue';

export const createSuccess = (message) => {
   return Vue.prototype.$flashStorage.flash(message, "success", {
      timeout: 5000,
      pauseOnInteract: true
   });
}

export const createError = (message, err) => {
   return Vue.prototype.$flashStorage.flash(`${message}: ${err}`, "error", {
      timeout: 5000,
      pauseOnInteract: true
   });
}