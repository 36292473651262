<template>
  <div>
    <div v-if="!favorites || favorites.length <= 0" class="padding__md flex__col bg-light-grey">
      <p class="text__bold text__center padding__sm">No favorite orders found</p>
      <router-link to="/new-order/order-details" class="btn btn__green btn__sm">New Order</router-link>
    </div>
    <ul class="hero-square">
      <favorite v-for="order in favorites" :key="order._id" :favorite="order" />
    </ul>
  </div>
</template>

<script>
export default {
  props: ["favorites"]
};
</script>

<style scoped lang="scss">
.flex__col {
  align-items: center;
}
</style>