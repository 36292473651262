import moment from 'moment';
import auth from "../../firebase/auth";
import api from "../../api";
import cleanSQL from "../../api/cleanSQL";
import { createSuccess, createError } from "../../libs/flash-messages";

const shouldSubscribe = (access, obj) => {
   const user = JSON.parse(window.localStorage.getItem("socket-details"));
   return user._id === obj.requester || user._id === obj.billingUser || access.includes(user.permission);
}

const removeDuplicateOrders = (newOrders, currOrders) => {
   const copyOrders = JSON.parse(JSON.stringify(newOrders));
   return copyOrders.filter(item => {
      return currOrders.findIndex(inner => inner._id === item._id) < 0;
   });
}

const state = {
   isAccount: false,
   filters: {
      requesterName: "",
      dateStart: "",
      dateEnd: "",
      isFavorite: "",
      status: [],
      orderType: "",
      products: [],
      recipientType: "",
      recipientName: "",
      billingType: "",
      loanNumber: "",
      includesProduct: [],
      dateSort: "newest-to-oldest"
   },
   orders: null,
   activeOrder: null,
   activeFavorite: null,
   ordersLoaded: false
};

const getters = {
   getOrders(state) {
      return state.orders;
   },
   getOrdersLoaded(state) {
      return state.ordersLoaded;
   },
   getActiveOrder(state) {
      if (!state.orders) return null;
      if (!state.activeOrder) return null;
      return state.orders.filter(item => {
         return item._id === state.activeOrder;
      })[0];
   },
   getFilteredOrders(state, getters) {
      if (!state.orders) return null;
      const filters = state.filters;
      const orders = state.isAccount ? getters.getUserOrders : state.orders;
      return orders
         .filter(order => {
            if (!filters.requesterName) return order;
            if (order.billingName) {
               return order.requesterName.toLowerCase().includes(filters.requesterName.toLowerCase()) || order.billingName.toLowerCase().includes(filters.requesterName.toLowerCase());
            }
            return order.requesterName.toLowerCase().includes(filters.requesterName.toLowerCase());
         })
         .filter(order => {
            if (!filters.dateStart) return order;
            return moment(order.createdAt).format("MM-DD-YYYY HH:mm:ss") >= moment(filters.dateStart, ["YYYY-MM-DD"]).startOf('day').format("MM-DD-YYYY HH:mm:ss");
         })
         .filter(order => {
            if (!filters.dateEnd) return order;
            return moment(order.createdAt).format("MM-DD-YYYY HH:mm:ss") <= moment(filters.dateEnd, ["YYYY-MM-DD"]).startOf('day').format("MM-DD-YYYY HH:mm:ss");
         })
         .filter(order => {
            if (filters.isFavorite === "") return order;
            return order.isFavorite === filters.isFavorite;
         })
         .filter(order => {
            if (!filters.status || filters.status.length <= 0) return order;
            return filters.status.includes(order.status);
         })
         .filter(order => {
            if (!filters.orderType) return order;
            if (filters.orderType === "bulk") {
               return order.bulkOrder;
            }
            if (filters.orderType === "standard") {
               return !order.bulkOrder;
            }
         })
         .filter(order => {
            if (filters.products.length <= 0) return order;
            return order.orderItems.map(item => {
               return filters.products.includes(item.product);
            }).some(val => val);
         })
         .filter(order => {
            if (!filters.recipientType) return order;
            return order.recipientType === filters.recipientType;
         })
         .filter(order => {
            if (!filters.recipientName) return order;
            return order.recipientName.toLowerCase().includes(filters.recipientName.toLowerCase())
               || order.coRecipientName.toLowerCase().includes(filters.recipientName.toLowerCase())
               || order.shippingName.toLowerCase().includes(filters.recipientName.toLowerCase());
         })
         .filter(order => {
            if (!filters.billingType) return order;
            return order.billingMethod === filters.billingType;
         })
         .filter(order => {
            if (!filters.loanNumber) return order;
            return order.loanNumber.toString().includes(filters.loanNumber);
         })
         .sort((a, b) => {
            if (filters.dateSort === "newest-to-oldest") {
               if (a.createdAt >= b.createdAt) return -1;
               if (a.createdAt <= b.createdAt) return 1;
               return 0;
            } else {
               if (a.createdAt >= b.createdAt) return 1;
               if (a.createdAt <= b.createdAt) return -1;
               return 0;
            }
         });
   },
   getOrderFilters(state) {
      return state.filters;
   },
   getHomeOrders(state, getters, rootState) {
      if (!state.orders) return null;
      const userId = rootState.user.userDetails._id;
      return state.orders
         .filter(item => {
            return item.requester === userId || item.billingUser === userId
         })
         .sort((a, b) => {
            if (a.createdAt < b.createdAt) return 1;
            if (a.createdAt > b.createdAt) return -1;
            return 0;
         }).slice(0, 4);
   },
   getHomeFavorites(state, getters, rootState) {
      if (!state.orders) return null;
      const userId = rootState.user.userDetails._id;
      return state.orders
         .filter(item => item.isFavorite && item.requester === userId)
         .sort((a, b) => {
            if (a.createdAt < b.createdAt) return 1;
            if (a.createdAt > b.createdAt) return -1;
            return 0;
         })
         .slice(0, 4);
   },
   getFavoriteOrders(state) {
      if (!state.orders) return null;
      return state.orders
         .filter(order => order.isFavorite)
         .sort((a, b) => {
            if (a.favoriteRank < b.favoriteRank) return 1;
            if (a.favoriteRank > b.favoriteRank) return -1;
            return 0;
         });
   },
   getUserOrders(state, getters, rootState) {
      if (!state.orders) return null;
      const userId = rootState.user.userDetails._id;
      return state.orders
         .filter(item => item.requester === userId || item.billingUser === userId)
         .sort((a, b) => {
            if (a.createdAt < b.createdAt) return 1;
            if (a.createdAt > b.createdAt) return -1;
            return 0;
         });
   },
   getActiveUserOrders(state, getters, rootState) {
      if (!state.orders) return null;
      const userId = rootState.users.activeUser;
      return state.orders
         .filter(item => item.requester === userId || item.billingUser === userId)
         .sort((a, b) => {
            if (a.createdAt < b.createdAt) return 1;
            if (a.createdAt > b.createdAt) return -1;
            return 0;
         });
   },
   getBulkItems(state, getters, rootState) {
      if (!state.orders) return null;
      return state.orders
         .filter(item => item.bulkOrder === rootState.bulk.activeBulkOrder);
   }
};

const actions = {
   startSetIsAccount({ commit }, bool) {
      commit("SET_IS_ACCOUNT", bool);
   },
   async startSetOrders({ commit }) {
      try {
         await auth.refreshFlow(auth.provider);
         const orders = await api.axiosGet("/orders");
         commit("SET_ORDERS", orders);
         commit("SET_ORDERS_LOADED", true);
         return Promise.resolve("success");
      } catch (err) {
         createError("Orders failed", err);
         return Promise.reject(err)
      }
   },
   async startSetBulkItems({ commit }, id) {
      try {
         await auth.refreshFlow(auth.provider);
         const orders = await api.axiosGet(`/orders/batch/${id}`)
         commit("SET_ORDERS", orders);
         return Promise.resolve("success");
      } catch (err) {
         createError("Orders failed", err);
         return Promise.reject(err);
      }
   },
   async startSetUserOrders({ commit }, user) {
      try {
         await auth.refreshFlow(auth.provider);
         const orders = await api.axiosGet(`/orders/user-orders/${user}`);
         commit("SET_ORDERS", orders);
         return Promise.resolve("success");
      } catch (err) {
         createError("Couldn't get orders", err);
         return Promise.reject(err);
      }
   },
   async startSetHomeInfo({ commit }) {
      try {
         await auth.refreshFlow(auth.provider);
         const recent = await api.axiosGet("/orders/user/home-orders");
         const favorites = await api.axiosGet("/orders/user/favorites");
         commit("SET_ORDERS", recent);
         commit("SET_ORDERS", favorites);
         return Promise.resolve("success");
      } catch (err) {
         createError("Orders failed", err);
         return Promise.reject(err);
      }
   },
   async startSetActiveFavorite({ commit }, id) {
      commit("SET_ACTIVE_FAVORITE", id)
   },
   startUnsetActiveOrder({ commit }, val) {
      commit("SET_ACTIVE_ORDER", val);
   },
   async startSetActiveOrder({ commit, state }, id) {
      let order = null;
      if (state.orders) {
         order = state.orders.filter(item => {
            return item._id === id;
         })[0];
      }
      if (order) {
         commit("SET_ACTIVE_ORDER", id);
         return Promise.resolve("success");
      } else {
         try {
            await auth.refreshFlow(auth.provider);
            const singleOrder = await api.axiosGet(`/orders/single/${id}`);
            if (singleOrder[0]) {
               commit("SET_ACTIVE_ORDER", id);
               commit("SET_SINGLE_ORDER", singleOrder[0]);
               return Promise.resolve("success");
            }
            throw Error("no order found");
         } catch (err) {
            createError("Error", "No order found by the id provided.");
            return Promise.reject(err);
         }
      }
   },
   async startCreateOrder({ commit }, form) {
      //create an order that only the requester and admin can see for now, 
      try {
         await auth.refreshFlow(auth.provider);
         const order = await api.axiosPost("/orders", form);
         commit("CREATE_ORDER", order);
         this._vm.$socket.emit("CREATE_ORDER", order);
         createSuccess("Order Started: Please add some products to the order");
         return Promise.resolve(order);
      } catch (err) {
         createError("Couldn't create order", err);
         return Promise.reject(err);
      }
   },
   async startCreateBatchOrder({ commit }, data) {
      try {
         await auth.refreshFlow(auth.provider);
         const orders = await api.axiosPost("/orders/batch", data);
         this._vm.$socket.emit("CREATE_BATCH_ORDER", orders);
         commit("COMPLETE_ORDER_SOCKET");
         createSuccess("Orders Added: Please add some products to each one");
         return Promise.resolve(orders);
      } catch (err) {
         createError("Failed to create orders", err);
         return Promise.reject(err);
      }
   },
   async startUpdateOrder({ commit }, form) {
      const id = form._id;
      const isBulkUpdate = form.bulk;
      delete form.bulk;
      try {
         await auth.refreshFlow(auth.provider);
         const order = await api.axiosPut(`/orders/${id}`, form);
         this._vm.$socket.emit("UPDATE_ORDER", order);
         commit("COMPLETE_ORDER_SOCKET");
         if (!isBulkUpdate) {
            order.status === "Started" ? createSuccess("Order Submitted!") : null;
         }
         return Promise.resolve("success");
      } catch (err) {
         createError("Couldn't update order", err);
         return Promise.reject(err);
      }
   },
   async startUpdateBatchOrder({ commit }, data) {
      try {
         await auth.refreshFlow(auth.provider);
         await api.axiosPut("/orders/batch", data);
         this._vm.$socket.emit("UPDATE_BATCH_ORDER", data);
         commit("COMPLETE_ORDER_SOCKET");
         createSuccess("Orders Updated!");
         return Promise.resolve("success");
      } catch (err) {
         createError("Failed to update orders", err);
         return Promise.reject(err);
      }
   },
   async startDeleteOrder({ commit, rootState, dispatch }, form) {
      const id = form._id;
      try {
         await auth.refreshFlow(auth.provider);
         const order = await api.axiosDelete(`/orders/${id}`, form);
         this._vm.$socket.emit("DELETE_ORDER", id);
         if (order.bulkOrder) {
            const bulkOrder = rootState.bulk.bulkOrders.filter(item => item._id === order.bulkOrder)[0];
            const bulk = {
               _id: bulkOrder._id,
               bulkItems: bulkOrder.bulkItems.filter(item => item !== order.bulkOrder)
            }
            await api.axiosPut(`/bulk-orders/order/${order.bulkOrder}`, bulk);
            this._vm.$socket.emit("UPDATE_BULK_ORDER", bulkOrder);
         }
         if (order.awardAmountUsed > 0) {
            const users = await dispatch("loadDelegates");
            const foundUser = users.filter(item => item._id === order.billingUser);
            const user = {
               _id: order.billingUser,
               awardBalance: foundUser.awardBalance + order.awardAmountUsed
            }
            await dispatch("startUpdateUser", user);
         }
         commit("COMPLETE_ORDER_SOCKET");
         createSuccess("Order Deleted");
         return Promise.resolve("success");
      } catch (err) {
         createError("Couldn't delete order", err);
         return Promise.reject(err);
      }
   },
   async startDeleteBatchOrder({ commit }, data) {
      try {
         await auth.refreshFlow(auth.provider);
         const bulk = await api.axiosDelete("/orders/batch", data);
         this._vm.$socket.emit("DELETE_BATCH_ORDER", data.orders);
         this._vm.$socket.emit("UPDATE_BULK_ORDER", bulk);
         commit("COMPLETE_ORDER_SOCKET");
         createSuccess("Orders Removed");
         return Promise.resolve("success");
      } catch (err) {
         createError("Failed to create orders", err);
         return Promise.reject(err);
      }
   },
   async startCreateOrderItem({ commit, state }, form) {
      try {
         await auth.refreshFlow(auth.provider);
         const orderItem = await api.axiosPost("/order-items", form);
         const foundOrder = state.orders.filter(item => item._id === orderItem.order)[0];
         if (!foundOrder) throw new Error("No order found for the given id");
         const balanceDue = foundOrder.totalCost + orderItem.quantity * orderItem.loCost - foundOrder.awardAmountUsed;
         const orderUpdate = {
            _id: foundOrder._id,
            orderItems: [...foundOrder.orderItems.map(item => item._id), orderItem._id],
            totalCost: foundOrder.totalCost + orderItem.quantity * orderItem.loCost,
            balanceDue: balanceDue > 0 ? balanceDue : 0
         };
         const order = await api.axiosPut(`/orders/${foundOrder._id}`, orderUpdate);
         this._vm.$socket.emit("UPDATE_ORDER", order);
         commit("COMPLETE_ORDER_SOCKET");
         createSuccess(`Order Updated: ${orderItem.productName} added`);
         return Promise.resolve("success");
      } catch (err) {
         createError("Couldn't update order", err);
         return Promise.reject(err);
      }
   },
   async startUpdateBatchProducts({ dispatch }, info) {
      try {
         let data;
         for (let i = 0; i < info.ids.length; i += 1) {
            data = null;
            data = info.products.map(item => {
               item.order = info.ids[i];
               return item;
            });
            await dispatch("startCreateBatchOrderItem", data);
         }
         createSuccess("Added products to orders!");
         return Promise.resolve("success");
      } catch (err) {
         createError("Failed to update orders", err);
         return Promise.reject(err);
      }
   },
   async startCreateBatchOrderItem({ commit }, data) {
      try {
         await auth.refreshFlow(auth.provider);
         const orderItems = await api.axiosPost("/order-items/batch", data);
         const foundOrder = state.orders.filter(item => item._id === orderItems[0].order)[0];
         if (!foundOrder) throw new Error("No order found for the given id");
         const sumCost = orderItems.reduce((a, b) => {
            return a + b.quantity * b.loCost
         }, 0);
         const balanceDue = foundOrder.totalCost + sumCost;
         const orderUpdate = {
            _id: foundOrder._id,
            orderItems: [...foundOrder.orderItems.map(item => item._id), ...orderItems.map(item => item._id)],
            totalCost: balanceDue > 0 ? balanceDue : 0,
            balanceDue: balanceDue > 0 ? balanceDue : 0
         };
         const order = await api.axiosPut(`/orders/${foundOrder._id}`, orderUpdate);
         this._vm.$socket.emit("UPDATE_ORDER", order);
         commit("COMPLETE_ORDER_SOCKET");
         // createSuccess(`Order Updated: products added`);
         return Promise.resolve("success");
      } catch (err) {
         createError("Failed to create orders", err);
         return Promise.reject(err);
      }
   },
   async startUpdateOrderItem({ commit, state }, form) {
      const id = form._id;
      try {
         await auth.refreshFlow(auth.provider);
         const orderItem = await api.axiosPut(`/order-items/${id}`, form);
         const foundOrder = JSON.parse(JSON.stringify(state.orders.filter(item => item._id === orderItem.order)[0]));
         if (!foundOrder) throw new Error("No order found for the given id");
         const itemIndex = foundOrder.orderItems.findIndex(item => item._id === orderItem._id);
         const qtyDelta = orderItem.quantity - foundOrder.orderItems[itemIndex].quantity;
         itemIndex >= 0 ? foundOrder.orderItems.splice(itemIndex, 1, orderItem) : foundOrder.orderItems.push(orderItem);
         const balanceDue = foundOrder.totalCost + qtyDelta * orderItem.loCost - foundOrder.awardAmountUsed;
         const orderUpdate = {
            _id: foundOrder._id,
            orderItems: [...foundOrder.orderItems.map(item => item._id)],
            totalCost: foundOrder.totalCost + qtyDelta * orderItem.loCost,
            balanceDue: balanceDue > 0 ? balanceDue : 0
         }
         const order = await api.axiosPut(`/orders/${foundOrder._id}`, orderUpdate);
         this._vm.$socket.emit("UPDATE_ORDER", order);
         commit("COMPLETE_ORDER_SOCKET");
         createSuccess(`Order Updated: ${orderItem.productName} updated`);
         return Promise.resolve("success");
      } catch (err) {
         createError("Couldn't update order", err);
         return Promise.reject(err);
      }
   },
   async startCompleteOrderItem({ commit }, form) {
      try {
         await auth.refreshFlow(auth.provider);
         const cleanForm = cleanSQL(form);
         const item = await api.axiosPost(`/inventory/out`, cleanForm);
         if (!item || !form.isInventoried) return Promise.resolve("success");
         const update = {
            _id: item.product_id,
            inventory: item.current_inventory
         }
         const product = await api.axiosPut(`/products/inventory/${update._id}`, update);
         this._vm.$socket.emit("UPDATE_PRODUCT", product);
         commit("COMPLETE_SOCKET");
         createSuccess(`Inventory for ${form.productName} updated`);
         return Promise.resolve(item);
      } catch (err) {
         createError("Couldn't update inventory of this product", err);
         return Promise.reject(err);
      }
   },
   async startUpdateBatchOrderItems({ commit }, data) {
      try {
         await auth.refreshFlow(auth.provider);
         await api.axiosPut("/order-items/batch", data);
         this._vm.$socket.emit("UPDATE_BATCH_ORDER_ITEMS", data);
         commit("COMPLETE_ORDER_SOCKET");
         createSuccess("Orders Updated!");
         return Promise.resolve("success");
      } catch (err) {
         createError("Failed to update orders", err);
         return Promise.reject(err);
      }
   },
   async startDeleteOrderItem({ commit, state }, orderItem) {
      try {
         await auth.refreshFlow(auth.provider);
         await api.axiosDelete(`/order-items/${orderItem._id}`, orderItem._id);
         const foundOrder = JSON.parse(JSON.stringify(state.orders.filter(item => item._id === orderItem.order)[0]));
         if (!foundOrder) throw new Error("No order found for the given id");
         const itemIndex = foundOrder.orderItems.findIndex(item => item._id === orderItem._id);
         if (itemIndex >= 0) foundOrder.orderItems.splice(itemIndex, 1);
         const balanceDue = foundOrder.totalCost - orderItem.quantity * orderItem.loCost - foundOrder.awardAmountUsed;
         const orderUpdate = {
            _id: foundOrder._id,
            orderItems: [...foundOrder.orderItems.map(item => item._id)],
            totalCost: foundOrder.totalCost - orderItem.quantity * orderItem.loCost,
            balanceDue: balanceDue > 0 ? balanceDue : 0
         };
         const order = await api.axiosPut(`/orders/${foundOrder._id}`, orderUpdate);
         this._vm.$socket.emit("UPDATE_ORDER", order);
         commit("COMPLETE_ORDER_SOCKET");
         createSuccess(`Order Updated: ${orderItem.productName} removed`);
         return Promise.resolve("success");
      } catch (err) {
         createError("Couldn't update order", err);
         return Promise.reject(err);
      }
   },
   startSetOrderFilters({ commit }, form) {
      commit("SET_ORDER_FILTERS", form);
   },
   startUnsetOrderFilters({ commit }) {
      const filters = {
         requesterName: "",
         dateStart: "",
         dateEnd: "",
         isFavorite: "",
         status: [],
         orderType: "",
         products: [],
         recipientType: "",
         recipientName: "",
         billingType: "",
         loanNumber: "",
         includesProduct: [],
         dateSort: "newest-to-oldest"
      }
      commit("SET_ORDER_FILTERS", filters);
   }
};

const mutations = {
   COMPLETE_ORDER_SOCKET() {
      return;
   },
   CREATE_ORDER(state, order) {
      if (state.orders) {
         return state.orders.push(order);
      }
      return state.orders = [order];
   },
   SET_IS_ACCOUNT(state, bool) {
      state.isAccount = bool;
   },
   SET_ORDERS(state, orders) {
      // if no orders then just add to state, otherwise remove dupes from new array and spread all orders into state.orders
      if (!state.orders) return state.orders = orders;
      const nonDupes = removeDuplicateOrders(orders, state.orders);
      state.orders = [...state.orders, ...nonDupes];
   },
   SET_ORDERS_LOADED(state, bool) {
      state.ordersLoaded = bool;
   },
   SET_SINGLE_ORDER(state, order) {
      if (!state.orders) {
         return state.orders = [order];
      }
      state.orders.push(order);
   },
   SET_ACTIVE_FAVORITE(state, id) {
      state.activeFavorite = id;
   },
   SET_ACTIVE_ORDER(state, id) {
      state.activeOrder = id;
   },
   SOCKET_CREATE_ORDER(state, order) {
      const access = ["admin", "super-admin"];
      if (!shouldSubscribe(access, order)) return;
      const found = state.orders.findIndex(item => item._id === order._id);
      if (found < 0) {
         if (state.orders) {
            return state.orders.push(order);
         }
         return state.orders = [order];
      }
      return state.orders.splice(found, 1, order);
   },
   SOCKET_CREATE_BATCH_ORDER(state, orders) {
      const access = ["admin", "super-admin"];
      if (!shouldSubscribe(access, orders[0])) return;
      if (!state.orders) {
         return state.orders = [...orders];
      }
      state.orders.push(...orders);
   },
   SOCKET_UPDATE_ORDER(state, order) {
      const access = ["admin", "super-admin"];
      if (!shouldSubscribe(access, order)) return;
      const index = state.orders.findIndex(item => {
         return item._id === order._id;
      });
      if (index >= 0) {
         return state.orders.splice(index, 1, order);
      }
      return state.orders.push(order);
   },
   SOCKET_UPDATE_BATCH_ORDER(state, data) {
      if (!state.orders) return;
      state.orders = state.orders.map(item => {
         if (data.orders.includes(item._id)) {
            item.status = data.status;
         }
         return item;
      });
   },
   SOCKET_UPDATE_BATCH_ORDER_ITEMS(state, data) {
      if (!state.orders) return;
      state.orders = state.orders.map(order => {
         order.orderItems = order.orderItems.map(item => {
            if (data.orders.includes(item._id)) {
               item.status = data.status;
            }
            return item;
         });
         return order;
      });
   },
   SOCKET_DELETE_ORDER(state, id) {
      const index = state.orders.findIndex(item => {
         return item._id === id;
      });
      if (index >= 0) {
         return state.orders.splice(index, 1);
      }
   },
   SOCKET_DELETE_BATCH_ORDER(state, orders) {
      const access = ["admin", "super-admin"];
      if (!shouldSubscribe(access, orders[0])) return;
      if (!state.orders) {
         return;
      }
      state.orders = state.orders.filter(item => {
         return !orders.includes(item._id);
      })
   },
   SOCKET_REMOVE_ORDERS_BULK(state, id) {
      if (!state.orders) return;
      state.orders = state.orders.filter(order => order.bulkOrder !== id);
   },
   SET_ORDER_FILTERS(state, form) {
      for (let key in form) {
         state.filters[key] = form[key];
      }
   }
};

export default {
   state,
   getters,
   actions,
   mutations
}