import moment from "moment";
import auth from "../../firebase/auth";
import api from "../../api";
import { createSuccess, createError } from "../../libs/flash-messages";

const shouldSubscribe = (access, obj) => {
   const user = JSON.parse(window.localStorage.getItem("socket-details"));
   return user._id === obj.requester || user._id === obj.billingUser || access.includes(user.permission);
}

const state = {
   filters: {},
   bulkOrders: null,
   activeBulkOrder: null,
   // activeBulkItem: null, 
   bulkUpdateSelection: [],
   bulkProductsTemplate: []
};

const getters = {
   getBulkOrders(state) {
      return state.bulkOrders;
   },
   getActiveBulkOrder(state) {
      if (!state.bulkOrders) return null;
      if (!state.activeBulkOrder) return null;
      return state.bulkOrders.filter(item => {
         return item._id === state.activeBulkOrder;
      })[0];
   },
   getFilteredBulkOrders(state) {
      if (!state.bulkOrders) return null;
      return state.bulkOrders;
   },
   getBulkUpdateSelection(state) {
      return state.bulkUpdateSelection;
   },
   getBulkProductsTemplate(state) {
      return state.bulkProductsTemplate;
   }
};

const actions = {
   async startSetBulkOrders({ commit }) {
      try {
         await auth.refreshFlow(auth.provider);
         const bulk = await api.axiosGet("/orders/user/bulk");
         commit("SET_BULK_ORDERS", bulk);
         return Promise.resolve("success");
      } catch (err) {
         createError("Bulk Orders failed", err);
         return Promise.reject(err);
      }
   },
   async startSetActiveBulkOrder({ commit, state }, id) {
      let order = null;
      if (state.bulkOrders) {
         order = state.bulkOrders.filter(item => {
            return item._id === id;
         })[0];
      }
      if (order) {
         commit("SET_ACTIVE_BULK_ORDER", id);
         return Promise.resolve("success");
      } else {
         try {
            const userId = JSON.parse(window.localStorage.getItem("socket-details"))._id;
            await auth.refreshFlow(auth.provider);
            const singleOrder = await api.axiosGet(`/bulk-orders/single/${id}/${userId}`);
            if (singleOrder[0]) {
               commit("SET_ACTIVE_BULK_ORDER", id);
               commit("SET_SINGLE_BULK_ORDER", singleOrder[0]);
               return Promise.resolve("success");
            }
            throw Error("no bulk order found");
         } catch (err) {
            createError("Error", "No order found by the id provided.");
            return Promise.reject(err);
         }
      }
   },
   async startCreateBulkOrder({ commit }, form) {
      //create an order that only the requester and admin can see for now, 
      try {
         await auth.refreshFlow(auth.provider);
         const order = await api.axiosPost("/bulk-orders", form);
         this._vm.$socket.emit("CREATE_BULK_ORDER", order);
         commit("COMPLETE_BULK_ORDER_SOCKET");
         createSuccess("Order Started: Please add some orders for this bulk order");
         return Promise.resolve(order);
      } catch (err) {
         createError("Couldn't create order", err);
         return Promise.reject(err);
      }
   },
   async startUpdateBulkProductsTemplate({ commit }, form) {
      commit("UPDATE_BULK_PRODUCTS_TEMPLATE", form);
   },
   async startDeleteBulkProductsTemplate({ commit }, form) {
      commit("DELETE_BULK_PRODUCTS_TEMPLATE", form);
   },
   startUpdateBulkUpdateSelection({ commit }, data) {
      commit("UPDATE_BULK_UPDATE_SELECTION", data);
   },
   startChangeBulkUpdateSelection({ commit }, data) {
      if (data.status) {
         commit("ADD_BULK_UPDATE_SELECTION", data._id);
      } else {
         commit("REMOVE_BULK_UPDATE_SELECTION", data._id);
      }
   },
   async startUpdateBulkOrder({ commit }, form) {
      const id = form._id;
      try {
         await auth.refreshFlow(auth.provider);
         const order = await api.axiosPut(`/bulk-orders/order/${id}`, form);
         this._vm.$socket.emit("UPDATE_BULK_ORDER", order);
         commit("COMPLETE_BULK_ORDER_SOCKET");
         return Promise.resolve("success");
      } catch (err) {
         createError("Couldn't update bulk order", err);
         return Promise.reject(err);
      }
   },
   async startDeleteBulkOrder({ commit }, form) {
      const id = form._id;
      try {
         await auth.refreshFlow(auth.provider);
         const bulkOrder = await api.axiosDelete(`/bulk-orders/order/${id}`, form);
         this._vm.$socket.emit("DELETE_BULK_ORDER", id);
         if (bulkOrder.bulkItems.length > 0) {
            this._vm.$socket.emit("REMOVE_ORDERS_BULK", id);
         }
         commit("COMPLETE_BULK_ORDER_SOCKET");
         createSuccess("Bulk Order Deleted");
         return Promise.resolve("success");
      } catch (err) {
         createError("Couldn't delete bulk order", err);
         return Promise.reject(err);
      }
   },
   async submitBulkOrder({ commit, dispatch, rootState }, active) {
      // this process is somewhat slow for now
      // potential solutions include:
      // bulk creation of tickets in Happy Fox: currently unavailable
      // bulk update of changed tickets with mongoose: currently does not accept unique/dynamic changes per found doc
      const orders = rootState.orders.orders.filter(item => item.bulkOrder === active._id);
      try {
         for (let i = 0; i < orders.length; i += 1) {
            const order = orders[i];
            let showRespaIssue;
            if (order.recipientType === "Internal Employee") {
               showRespaIssue = false;
            }
            if (order.coRecipientName) {
               showRespaIssue = order.totalCost > 50;
            } else {
               showRespaIssue = order.totalCost > 25;
            }
            let data = {
               active,
               order,
               showRespaIssue
            }
            const id = await dispatch("createBulkTicket", data);
            const update = {
               _id: order._id,
               hfTicketId: id,
               status: "Submitted",
               bulk: true
            }
            await dispatch("startUpdateOrder", update);
         }
         commit("COMPLETE_BULK_ORDER_SOCKET");
         dispatch("startSetSubLoading", false);
         createSuccess("All orders have been submitted!")
         return Promise.resolve("success");
      } catch (err) {
         return Promise.reject(err);
      }
   },
   async startBulkUpdate({ commit, dispatch, rootState }, data) {
      // this process is somewhat slow for now
      // potential solutions include:
      // bulk creation of tickets in Happy Fox: currently unavailable
      const user = rootState.user.userDetails.hfUserId;
      if (!user) return createError("Cannot Submit", "No Happy Fox user id is present");
      try {
         const updates = {
            orders: data.orders,
            status: data.status
         }
         if (data.orderType === "Order" && data.status) {
            await dispatch("startUpdateBatchOrder", updates);
         }
         if (data.orderType === "Order Item" && data.status) {
            await dispatch("startUpdateBatchOrderItems", updates);
            if (!rootState.orders.ordersLoaded) {
               await dispatch("startSetOrders");
            }
            for (let i = 0; i < data.orders.length; i += 1) {
               const order = rootState.orders.orders.filter(item => item.orderItems.map(choice => choice._id).includes(data.orders[i]))[0];
               const orderItem = order.orderItems.filter(item => item._id === data.orders[i])[0];
               const form = {
                  requesterName: order.requesterName,
                  requesterEmail: order.requesterEmail ? order.requesterEmail : "wowrequests@fbhl.com",
                  billingName: order.billingName,
                  billingEmail: order.billingEmail ? order.billingEmail : "wowrequests@fbhl.com",
                  order: order._id,
                  hfTicketId: order.hfTicketId,
                  recipientName: order.recipientName,
                  coRecipientName: order.coRecipientName,
                  shippingName: order.shippingName,
                  recipientType: order.recipientType,
                  loanNumber: order.loanNumber,
                  requestDate: moment(order.createdAt).format("YYYY-MM-DD hh:mm:ss"),
                  orderItem: orderItem._id,
                  product: orderItem.product,
                  productName: orderItem.productName,
                  quantity: orderItem.quantity,
                  loCost: orderItem.loCost,
                  itemCost: orderItem.itemCost,
                  awardAmountUsed: order.awardAmountUsed,
                  receipt: orderItem.receipt,
                  billingMethod: order.billingMethod,
                  milestone: order.milestone,
                  comments: order.comments,
                  isInventoried: orderItem.isInventoried,
                  bulkOrder: order.bulkOrder ? true : false
               }
               //check if non-nullable fields have any issues before submitting?
               await dispatch("startCompleteOrderItem", form);
            }
         }
         if (data.shouldUpdate) {
            if (!rootState.orders.ordersLoaded) {
               await dispatch("startSetOrders");
            }
            for (let i = 0; i < data.orders.length; i += 1) {
               const order = rootState.orders.orders.filter(item => item._id === data.orders[i])[0];
               const body = {
                  notify_user: 1,
                  staff: user,
                  status: data.ticketStatus,
                  text: data.response
               };
               await api.axiosPut(`/tickets/${order.hfTicketId}`, body);
            }
         }
         commit("COMPLETE_BULK_ORDER_SOCKET");
         dispatch("startSetSubLoading", false);
         createSuccess("Everything has been updated!")
         return Promise.resolve("success");
      } catch (err) {
         dispatch("startSetSubLoading", false);
         return Promise.reject(err);
      }
   }
};

const mutations = {
   COMPLETE_BULK_ORDER_SOCKET() {
      return;
   },
   SET_BULK_ORDERS(state, bulk) {
      return state.bulkOrders = bulk;
   },
   SET_SINGLE_BULK_ORDER(state, order) {
      if (!state.bulkOrders) {
         return state.bulkOrders = [order];
      }
      state.bulkOrders.push(order);
   },
   SET_ACTIVE_BULK_ORDER(state, id) {
      state.activeBulkOrder = id;
   },
   SOCKET_CREATE_BULK_ORDER(state, order) {
      const access = ["admin", "super-admin"];
      if (!shouldSubscribe(access, order)) return;
      if (!state.bulkOrders) {
         return state.bulkOrders = [order];
      }
      state.bulkOrders.push(order);
   },
   SOCKET_UPDATE_BULK_ORDER(state, order) {
      const access = ["admin", "super-admin"];
      if (!shouldSubscribe(access, order)) return;
      const index = state.bulkOrders.findIndex(item => {
         return item._id === order._id;
      });
      if (index >= 0) {
         return state.bulkOrders.splice(index, 1, order);
      }
      return state.bulkOrders.push(order);
   },
   SOCKET_DELETE_BULK_ORDER(state, id) {
      const index = state.bulkOrders.findIndex(item => {
         return item._id === id;
      });
      if (index >= 0) {
         return state.bulkOrders.splice(index, 1);
      }
   },
   UPDATE_BULK_PRODUCTS_TEMPLATE(state, form) {
      const copyForm = JSON.parse(JSON.stringify(form));
      // const index = state.bulkProductsTemplate.findIndex(item => item._id === copyForm._id);
      // if (index >= 0) {
      //    return state.bulkProductsTemplate.splice(index, 1, copyForm)
      // }
      return state.bulkProductsTemplate.push(copyForm);
   },
   DELETE_BULK_PRODUCTS_TEMPLATE(state, form) {
      const index = state.bulkProductsTemplate.findIndex(item => item.product === form.product && item.quantity === form.quantity);
      return state.bulkProductsTemplate.splice(index, 1);
   },
   UPDATE_BULK_UPDATE_SELECTION(state, data) {
      return state.bulkUpdateSelection = data;
   },
   ADD_BULK_UPDATE_SELECTION(state, id) {
      let found = state.bulkUpdateSelection.findIndex(item => item === id) >= 0;
      if (found) {
         return
      }
      state.bulkUpdateSelection.push(id);
   },
   REMOVE_BULK_UPDATE_SELECTION(state, id) {
      state.bulkUpdateSelection = state.bulkUpdateSelection.filter(item => item !== id);
   }
};

export default {
   state,
   getters,
   actions,
   mutations
}