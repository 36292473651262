<template>
  <footer>
    <div class="bg-sky flex__right">
      <div class="flex__center top-button pointer" @click="scrollToTop">
        <p class="text__bold text__pine">
          To Top
          <font-awesome-icon icon="level-up-alt" size="1x" class="ml-10 icon" />
        </p>
      </div>
    </div>
    <div class="footer-main">
      <div class="container footer-main__grid">
        <Main />
        <img
          src="https://cdn.fbhl.com/WOW-Assets/logo/WOW-logo-wht-horizontial.png"
          alt="WOW with Flat Branch Home Loans"
          width="100%"
          height="100%"
          class="logo"
        />
        <Social />
        <Other />
      </div>
    </div>
    <Sub />
  </footer>
</template>

<script>
import Main from "./Main";
import Social from "./Social";
import Other from "./Other";
import Sub from "./Sub";
export default {
  components: {
    Main,
    Social,
    Other,
    Sub
  },
  methods: {
    scrollToTop() {
      this.$emit("scrollToTop");
    }
  }
};
</script>

<style scoped lang="scss">
footer {
  background-color: $dark-blue;
}
.bg-sky {
  background-color: $sky;
}
.top-button {
  padding: 10px 15px;
  background-color: $pistachio;
  &:hover p .icon {
    animation: bounce 1s infinite;
  }
}
.footer-main {
  padding: 50px 0;
}
.footer-main__grid {
  @include grid($cols: 1fr, $row-gap: 35px);
}
.logo {
  width: 225px;
  height: 75px;
  margin: auto;
  margin-top: -25px;
  margin-bottom: -10px;
}
@keyframes bounce {
  0% {
    transform: translateY(0px);
  }
  45% {
    transform: translateY(-5px);
  }
  55% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0px);
  }
}
</style>