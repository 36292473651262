<template>
  <section class="notification" v-if="getNotificationShown">
    <p
      v-if="getNotes && getNotes.notification"
      class="container text__white text__center"
    >{{ getNotes.notification }}</p>
    <div class="icon pointer" @click="toggleNotification">
      <font-awesome-icon icon="times" size="lg" />
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "notification",
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["getNotificationShown", "getNotes"])
  },
  methods: {
    ...mapActions(["startSetNotificationShown"]),
    toggleNotification() {
      return this.startSetNotificationShown();
    }
  }
};
</script>

<style scoped lang="scss">
.notification {
  background-color: $maroon;
  color: #fff;
  position: relative;
  padding: 5px 0;
  z-index: 3;
}
.icon {
  @include pos-abs($pos: absolute, $top: 5px, $left: null, $right: 15px, $z: 5);
  color: #fff;
}
</style>