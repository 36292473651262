import store from "../store";
// import Pages from "../components/Pages";
import { checkAccess, adminAccess, superAccess } from "./libs/helpers";

const loadView = view => {
   return () => import(/* webpackChunkName: "set-admin" */ `../components/Pages/${view}/Index.vue`)
}

export default [
   {
      path: "/admin",
      name: "admin",
      component: loadView("Admin"),
      beforeEnter: async (to, from, next) => {
         const access = ["admin", "super-admin", "management"];
         await checkAccess(access, to, from, next, true);
         try {
            if (!store.getters.getUsers) await store.dispatch("startSetUsers");
            if (!store.getters.getOrdersLoaded) store.dispatch("startSetOrders")
            next();
         } catch (err) {
            next("/admin");
         }
      }
   },
   {
      path: "/admin/help",
      name: "admin-help",
      component: loadView("AdminHelp"),
      beforeEnter: async (to, from, next) => {
         const access = ["admin", "super-admin", "management"];
         await checkAccess(access, to, from, next, true);
      }
   },
   {
      path: "/admin/products",
      name: "admin-products",
      component: loadView("Products"),
      beforeEnter: adminAccess
   },
   {
      path: "/admin/products/new",
      name: "admin-products-new",
      component: loadView("NewProduct"),
      beforeEnter: superAccess
   },
   {
      path: "/admin/products/:product",
      name: "admin-products-product",
      component: loadView("Product"),
      beforeEnter: async (to, from, next) => {
         const access = ["admin", "super-admin"];
         checkAccess(access, to, from, next, false);
         try {
            await store.dispatch("startSetActiveProduct", to.params.product)
            next();
         } catch (err) {
            next("/admin/products");
         }
      }
   },
   {
      path: "/admin/users",
      name: "users",
      component: loadView("Users"),
      beforeEnter: async (to, from, next) => {
         const access = ["admin", "super-admin"];
         await checkAccess(access, to, from, next, false);
         try {
            if (!store.getters.getUsers) await store.dispatch("startSetUsers");
            next();
         } catch (err) {
            next("/admin");
         }
      }
   },
   {
      path: "/admin/users/new",
      name: "new-user",
      component: loadView("NewUser"),
      beforeEnter: superAccess
   },
   {
      path: "/admin/users/:user",
      name: "user",
      component: loadView("User"),
      beforeEnter: async (to, from, next) => {
         const access = ["admin", "super-admin"];
         await checkAccess(access, to, from, next, false);
         try {
            store.dispatch("startSetIsSubmitting", { bool: true, heading: "Loading" });
            if (!store.state.users.users) await store.dispatch("startSetUsers");
            await store.dispatch("startSetActiveUser", to.params.user);
            await store.dispatch("startSetUserOrders", to.params.user);
            await store.dispatch("startSetUserBulkOrders", to.params.user);
            store.dispatch("startSetIsSubmitting", { bool: false });
            next();
         } catch (err) {
            store.dispatch("startSetIsSubmitting", { bool: false });
            next("/admin/users");
         }
      }
   },
   {
      path: "/admin/site-setup",
      name: "site-setup",
      component: loadView("SiteSetup"),
      beforeEnter: superAccess
   },
   // {
   //    path: "/admin/inventory",
   //    name: "inventory",
   //    component: loadView("Inventory"),
   //    beforeEnter: async (to, from, next) => {
   //       const access = ["super-admin"];
   //       checkAccess(access, to, from, next, false);
   //       try {
   //          if (!store.getters.getInventory) await store.dispatch("startSetInventory");
   //          next();
   //       } catch (err) {
   //          next("/admin");
   //       }
   //    }
   // },
   {
      path: "/admin/inventory-products",
      name: "inventory-products",
      component: loadView("InventoryProducts"),
      beforeEnter: async (to, from, next) => {
         store.dispatch("startUnsetActiveProduct", null);
         const access = ["super-admin"];
         await checkAccess(access, to, from, next, true);
         // try {
         //    if (!store.getters.getInventory) await store.dispatch("startSetInventory");
         //    next();
         // } catch (err) {
         //    next("/admin");
         // }
      }
   },
   {
      path: "/admin/reporting",
      name: "reporting",
      component: loadView("Reporting"),
      beforeEnter: async (to, from, next) => {
         const access = ["admin", "super-admin", "management"];
         await checkAccess(access, to, from, next, true);
      }
   },
   {
      path: "/admin/past-orders",
      name: "admin-past-orders",
      component: loadView("PastOrders"),
      beforeEnter: async (to, from, next) => {
         store.dispatch("startSetIsAccount", false);
         const access = ["admin", "super-admin"];
         await checkAccess(access, to, from, next, false);
         try {
            store.dispatch("startSetIsSubmitting", { bool: true, heading: "Loading" });
            if (!store.getters.getOrdersLoaded) await store.dispatch("startSetOrders");
            store.dispatch("startSetIsSubmitting", { bool: false });
            next();
         } catch (err) {
            next("/admin");
         }
      }
   }
]