<template>
  <label>
    <span class="flex__col">
      <span>{{ cust.name }}</span>
      <span v-if="cust.type === 'textarea'" class="text__sm">(up to {{ cust.charLimit }} characters)</span>
    </span>
    <select v-if="cust.type === 'select'" @change="emitSendValue" v-model="form.option">
      <option value></option>
      <option v-for="option in cust.options" :key="option" :value="option">{{ option }}</option>
    </select>
    <input v-if="cust.type === 'text'" type="text" @change="emitSendValue" v-model="form.option" />
    <div v-if="cust.type === 'textarea'" class="form--comments">
      <textarea rows="3" @change="emitSendValue" :maxlength="cust.charLimit" v-model="form.option" />
      <canned @setMessage="setResponse" />
    </div>
  </label>
</template>

<script>
export default {
  props: ["index", "cust", "selected"],
  data() {
    return {
      form: {
        name: this.cust ? this.cust.name : null,
        option: this.selected ? this.selected.option : null
      }
    };
  },
  watch: {
    selected: {
      handler: function(newVal) {
        this.form.option = newVal.option;
      },
      deep: true
    }
  },
  methods: {
    emitSendValue() {
      this.$emit("sendvalue", this.form);
    },
    setResponse(e) {
      this.form.option = e.message;
      this.emitSendValue();
    }
  }
};
</script>

<style scoped lang="scss">
label {
  height: fit-content;
}
</style>