<template>
  <label v-if="message" class="form-sub--error">
    <span></span>
    <!-- placeholder -->
    <span>{{ message }}</span>
  </label>
</template>

<script>
export default {
  props: ["message"]
};
</script>

<style scoped lang="scss">
.form-sub--error {
  margin-top: -15px;
  span {
    font-weight: 400;
    font-size: $text-sm;
    color: $maroon;
  }
}
</style>