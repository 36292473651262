import store from "../store";
import { checkAccess, checkLogin } from "./libs/helpers";

const loadView = view => {
   return () => import(/* webpackChunkName: "set-new-order" */ `../components/Pages/${view}/Index.vue`)
}

export default [
   {
      path: "/new-order",
      redirect: "/new-order/order-details"
   },
   {
      path: "/new-order/order-details",
      name: "order-details",
      component: loadView("OrderDetails"),
      beforeEnter: async (to, from, next) => {
         await checkLogin(to, from, next, false);
         try {
            await store.dispatch("startSetActiveFavorite", to.query.id);
            next();
         } catch (err) {
            return next(from.fullPath);
         }
      }
   },
   {
      path: "/new-order/:order/products",
      name: "order-products",
      component: loadView("Products"),
      beforeEnter: async (to, from, next) => {
         await checkAccess(null, to, from, next, false);
         try {
            await store.dispatch("startSetActiveOrder", to.params.order);
            next()
         } catch (err) {
            return next(from.fullPath);
         }
      }
   },
   {
      path: "/new-order/:order/products/:product",
      name: "order-products-product",
      component: loadView("Product"),
      beforeEnter: async (to, from, next) => {
         await checkAccess(null, to, from, next, false);
         try {
            await store.dispatch("startSetActiveOrder", to.params.order);
            await store.dispatch("startSetActiveProduct", to.params.product);
            next();
         } catch (err) {
            return next(from.fullPath)
         }
      }
   },
   {
      path: "/new-order/:order/order-confirm",
      name: "order-confirm",
      component: loadView("OrderConfirm"),
      beforeEnter: async (to, from, next) => {
         await checkAccess(null, to, from, next, false);
         try {
            await store.dispatch("startSetActiveOrder", to.params.order);
            next()
         } catch (err) {
            return next(from.fullPath);
         }
      }
   }
]