<template>
  <div class="account--message">
    <h3 class="text__center">New Message</h3>
    <div class="account--message-content">
      <label>
        Response Name*
        <input type="text" v-model="response.name" placeholder="response name" />
      </label>
      <label>
        Message*
        <textarea v-model="response.message" rows="5" placeholder="New message here" />
      </label>
    </div>
    <div class="dual--buttons">
      <div class="btn__red" @click="clear">
        <font-awesome-icon icon="trash-alt" size="1x" class="mr-10" />Clear
      </div>
      <div class="btn__green btn__confirm" @click="submit">
        <font-awesome-icon icon="plus-circle" size="1x" class="mr-10" />Submit
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["user"],
  data() {
    return {
      response: {
        name: "",
        message: ""
      }
    };
  },
  methods: {
    ...mapActions(["startSetIsSubmitting", "startUpdateSelf"]),
    toggleEditing() {
      this.editing = !this.editing;
    },
    async submit() {
      if (this.response.message.length > 3) {
        this.startSetIsSubmitting({ bool: true, heading: "Adding" });
        try {
          const copyUser = JSON.parse(JSON.stringify(this.user));
          copyUser.responses.push(this.response);
          const form = {
            _id: copyUser._id,
            responses: copyUser.responses
          };
          await this.startUpdateSelf(form);
          this.startSetIsSubmitting({ bool: false });
          this.clear();
        } catch (err) {
          this.startSetIsSubmitting({ bool: false });
        }
      }
    },
    clear() {
      this.response.name = "";
      this.response.message = "";
    }
  }
};
</script>