import Vue from 'vue'
import App from './App.vue'
import store from "./store";
import router from "./router";

/* eslint-disable */
//styles
import style from "./static/styles/main.scss";
import 'simplebar/dist/simplebar.min.css';
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

//plugins
// import scrollLoader from "./plugins/scroll-loader";
import socket from "./plugins/socket";
import fas from "./plugins/fontawesome";
import mask from "./plugins/v-mask";
import simplebar from "./plugins/simplebar";
import flashMessage from "./plugins/vue-flash-message";
import tinymce from "./plugins/tinymce";
import csvImport from "./plugins/csv-import";
import meta from "./plugins/meta";
import vueexcel from "./plugins/vueexcel";
/* eslint-enable */

//global components
import Delete from "./components/Delete";
import Exit from "./components/Exit";
import FormError from "./components/FormError";
import SingleCust from "./components/SingleCust";
import ExitButton from "./components/ExitButton";
//import Respa from "./components/Respa";
import OrderGrid from "./components/OrderGrid";
import FavoriteGrid from "./components/FavoriteGrid";
import OrderTile from "./components/OrderTile";
import FavoriteTile from "./components/FavoriteTile";
import Messages from "./components/Messages";
import Canned from "./components/Canned";
Vue.component("delete", Delete);
Vue.component("exit", Exit);
Vue.component("form-error", FormError);
Vue.component("single-cust", SingleCust);
Vue.component("exit-button", ExitButton);
//Vue.component("respa", Respa);
Vue.component("order-grid", OrderGrid);
Vue.component("favorite-grid", FavoriteGrid);
Vue.component("order", OrderTile);
Vue.component("favorite", FavoriteTile);
Vue.component("messages", Messages);
Vue.component("canned", Canned);

Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
