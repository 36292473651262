// communication with API is stored in this file
import axios from "axios";

const BASE_URL = process.env.NODE_ENV === 'development' ? 'http://localhost:3001/api' : 'https://wowapi.fbhl.com/api';

const config = (data) => {
   const INFO = JSON.parse(localStorage.getItem("user"));
   let options;
   if (INFO) {
      options = {
         headers: {
            "X-AUTH-TOKEN": `Bearer ${INFO.token}`,
            "user": `${JSON.stringify(INFO).replace(/’/g, "")}`
         }
      }
      if (data) {
         options.data = { data };
      }
      return options;
   } else {
      return {}
   }
}

export default {
   // route should start with "/" (might only be "/"), usually /api/
   async axiosPostLog(route, data) {
      try {
         const res = await axios.post(`${BASE_URL}${route}`, { data }, config());
         return Promise.resolve(res.data)
      } catch (err) {
         return Promise.reject(err.response.data.message);
      }
   },
   async axiosGet(route) {
      try {
         const res = await axios.get(`${BASE_URL}${route}`, config());
         return Promise.resolve(res.data);
      } catch (err) {
         return Promise.reject(err.response.data.message);
      }
   },
   async axiosPost(route, data) {
      try {
         const res = await axios.post(`${BASE_URL}${route}`, data, config());
         return Promise.resolve(res.data);
      } catch (err) {
         return Promise.reject(err.response.data.message);
      }
   },
   async axiosPut(route, data) {
      try {
         const res = await axios.put(`${BASE_URL}${route}`, data, config());
         return Promise.resolve(res.data);
      } catch (err) {
         return Promise.reject(err.response.data.message);
      }
   },
   async axiosDelete(route, data) {
      try {
         const res = await axios.delete(`${BASE_URL}${route}`, config(data));
         return Promise.resolve(res.data);
      } catch (err) {
         return Promise.reject(err.response.data.message);
      }
   }
}