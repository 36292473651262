// Your web app's Firebase configuration

const fbConfig = {
   apiKey: "AIzaSyDSKZjGmInARPs2acw2ALjD_IzozGGH3og",
   authDomain: "wow-api-e7970.firebaseapp.com",
   databaseURL: "https://wow-api-e7970.firebaseio.com",
   projectId: "wow-api-e7970",
   storageBucket: "wow-api-e7970.appspot.com",
   messagingSenderId: "909536827329",
   appId: "1:909536827329:web:762cfa0acd047bd0384140"
};

export default fbConfig;