<template>
  <div class="modal-exit flex__center pointer" @click="emitToggleModal">
    <font-awesome-icon icon="times" size="2x" />
  </div>
</template>

<script>
export default {
  methods: {
    emitToggleModal() {
      this.$emit("toggleModal");
    }
  }
};
</script>

<style scoped lang="scss">
.modal-exit {
  color: #fff;
  background-color: $maroon;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 1px solid #fff;
  @include pos-abs($pos: absolute, $top: -15px, $left: null, $right: -15px);
}
</style>