import api from "../../api";
import auth from "../../firebase/auth";
import { createSuccess, createError } from "../../libs/flash-messages";

const state = {
   categories: null
}

const getters = {
   getCategories(state) {
      if (state.categories) {
         return state.categories.sort((a, b) => {
            if (a.name > b.name) {
               return 1;
            } else if (a.name < b.name) {
               return -1;
            } else {
               return 0;
            }
         });
      }
      return [];
   }
}

const actions = {
   async startSetCategories({ commit }) {
      try {
         const categories = await api.axiosGet("/public/categories");
         commit("SET_CATEGORIES", categories);
         return Promise.resolve("success");
      } catch (err) {
         createError("Categories failed", err);
         return Promise.reject(err);
      }
   },
   async startCreateCategory({ commit }, form) {
      try {
         await auth.refreshFlow(auth.provider);
         const category = await api.axiosPost(`/categories`, form);
         this._vm.$socket.emit("CREATE_CATEGORY", category);
         commit("COMPLETE_SOCKET");
         createSuccess(`Category: ${form.name} added`);
         return Promise.resolve("success");
      } catch (err) {
         createError("Failed to create", err);
         return Promise.reject(err);
      }
   },
   async startUpdateCategory({ commit }, form) {
      const id = form._id;
      try {
         await auth.refreshFlow(auth.provider);
         const category = await api.axiosPut(`/categories/${id}`, form);
         this._vm.$socket.emit("UPDATE_CATEGORY", category);
         commit("COMPLETE_SOCKET");
         createSuccess(`Category: ${form.name} updated`);
         return Promise.resolve("success");
      } catch (err) {
         createError("Failed to update", err);
         return Promise.reject(err);
      }
   },
   async startDeleteCategory({ commit }, form) {
      const id = form._id;
      try {
         await auth.refreshFlow(auth.provider);
         await api.axiosDelete(`/categories/${id}`);
         this._vm.$socket.emit("DELETE_CATEGORY", id);
         this._vm.$socket.emit("DELETE_PRODUCT_CATEGORY", id);
         commit("COMPLETE_SOCKET");
         createSuccess(`Category: ${form.name} deleted`);
         return Promise.resolve("success");
      } catch (err) {
         createError("Failed to delete", err);
         return Promise.reject(err);
      }
   }
}

const mutations = {
   COMPLETE_SOCKET() {
      return;
   },
   SET_CATEGORIES(state, data) {
      return state.categories = data;
   },
   SOCKET_CREATE_CATEGORY(state, obj) {
      if (state.categories) {
         return state.categories.push(obj);
      }
      return state.categories = [obj];
   },
   SOCKET_UPDATE_CATEGORY(state, obj) {
      const index = state.categories.findIndex(item => {
         return item._id === obj._id;
      });
      if (index >= 0) {
         return state.categories.splice(index, 1, obj);
      }
      return state.categories.push(obj);
   },
   SOCKET_DELETE_CATEGORY(state, id) {
      const index = state.categories.findIndex(item => {
         return item._id === id;
      });
      if (index >= 0) {
         return state.categories.splice(index, 1);
      }
   }
}

export default {
   state,
   getters,
   actions,
   mutations
}