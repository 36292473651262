<template>
  <div>
    <div v-if="!navToggled" class="nav--icon nav--icon-bars pointer" @click="toggleNav">
      <font-awesome-icon icon="bars" size="3x" class="icon" />
    </div>
    <div v-else class="nav--icon nav--icon-times pointer" @click="toggleNav">
      <font-awesome-icon icon="times" size="3x" class="icon" />
    </div>
    <transition name="nav--slide">
      <NavList v-if="navToggled" @toggleNav="toggleNav" />
    </transition>
  </div>
</template>

<script>
import NavList from "./NavList";
export default {
  name: "Nav",
  components: {
    NavList
  },
  data() {
    return {
      navToggled: false
    };
  },
  methods: {
    toggleNav() {
      this.navToggled = !this.navToggled;
    }
  }
};
</script>

<style scoped lang="scss">
$slide-speed: 0.25s;
.nav--icon {
  color: #fff;
  width: 50px;
  height: 50px;
  @include flex($just: center, $align: center);
}
.nav--slide-enter {
  transform: translateX(100%);
}
.nav--slide-enter-to {
  transform: translateX(0%);
  transition: transform $slide-speed ease-in-out;
}
.nav--slide-leave {
  transform: translateX(0%);
}
.nav--slide-leave-to {
  transform: translateX(100%);
  transition: transform $slide-speed ease-in-out;
}
</style>