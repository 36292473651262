import api from "../../api";
import auth from "../../firebase/auth";
import { createSuccess, createError } from "../../libs/flash-messages";

const state = {
   filters: {
      name: "",
      email: "",
      permission: [],
      isActive: "",
      bulkEnabled: "",
      sort: "a-z"
   },
   users: null,
   userBulkOrders: null,
   activeUser: null
};

const getters = {
   getUsers(state) {
      if (!state.users) return null;
      return state.users.sort((a, b) => {
         if (a.displayName < b.displayName) return -1;
         if (a.displayName > b.displayName) return 1;
         return 0;
      });
   },
   getUserBulkOrders(state) {
      return state.userBulkOrders;
   },
   getActiveUser(state) {
      if (!state.activeUser) return null;
      return state.users.filter(user => {
         return user._id === state.activeUser;
      })[0];
   },
   getFilteredUsers(state) {
      if (!state.users) return null;
      const filters = state.filters;
      return state.users
         .filter(item => {
            if (!filters.name) return item;
            return item.displayName.toLowerCase().includes(filters.name.toLowerCase());
         })
         .filter(item => {
            if (!filters.email) return item;
            return item.email.toLowerCase().includes(filters.email.toLowerCase());
         })
         .filter(item => {
            if (!filters.permission || filters.permission.length <= 0) return item;
            return filters.permission.includes(item.permission);
         })
         .filter(item => {
            if (filters.isActive === "") return item;
            return item.enabled === filters.isActive;
         })
         .filter(item => {
            if (filters.bulkEnabled === "") return item;
            return item.bulkEnabled === filters.bulkEnabled;
         })
         .sort((a, b) => {
            if (filters.sort === "a-z") {
               if (a.displayName < b.displayName) return -1;
               if (a.displayName > b.displayName) return 1;
               return 0;
            } else {
               if (a.displayName > b.displayName) return -1;
               if (a.displayName < b.displayName) return 1;
               return 0;
            }
         });
   },
   getUserFilters(state) {
      return state.filters;
   },
   getUserStats(state) {
      if (!state.users) return {
         users: 0,
         admins: 0,
         active: 0,
         bulk: 0
      }
      const users = state.users.length;
      const admins = state.users.filter(user => (user.permission === "admin" || user.permission === "super-admin") && user.enabled).length;
      const active = state.users.filter(user => user.enabled).length;
      const bulk = state.users.filter(user => user.enabled && user.bulkEnabled).length
      return {
         users,
         admins,
         active,
         bulk
      }
   }
};

const actions = {
   async startSetUsers({ commit }) {
      try {
         auth.refreshFlow(auth.provider);
         const users = await api.axiosGet("/users");
         commit("SET_USERS", users);
         return Promise.resolve("success");
      } catch (err) {
         createError("Users failed", err);
         return Promise.reject(err);
      }
   },
   async startCreateUser({ commit }, form) {
      try {
         auth.refreshFlow(auth.provider);
         const user = await api.axiosPost("/users", form);
         this._vm.$socket.emit("CREATE_USER", user);
         commit("COMPLETE_SOCKET");
         createSuccess(`User: ${user.displayName} created`);
         return Promise.resolve("success");
      } catch (err) {
         createError("Users failed", err);
         return Promise.reject(err);
      }
   },
   async startUpdateUser({ commit }, form) {
      const id = form._id;
      try {
         auth.refreshFlow(auth.provider);
         const user = await api.axiosPut(`/users/${id}`, form);
         this._vm.$socket.emit("UPDATE_USER", user);
         this._vm.$socket.emit("UPDATE_USER_ADMIN", user);
         commit("COMPLETE_SOCKET");
         createSuccess(`User: ${user.displayName} updated`);
         return Promise.resolve("success");
      } catch (err) {
         createError("Failed to update", err);
         return Promise.reject(err);
      }
   },
   async startSetUserBulkOrders({ commit }, id) {
      try {
         await auth.refreshFlow(auth.provider);
         const bulk = await api.axiosGet(`/users/${id}/bulk`);
         commit("SET_USER_BULK_ORDERS", bulk);
         return Promise.resolve("success");
      } catch (err) {
         createError("Bulk Orders failed", err);
         return Promise.reject(err);
      }
   },
   async startDeleteUser({ commit }, form) {
      //disabled due to potential issues with deleted users in past orders, other users delegates, etc
      const id = form._id;
      commit("COMPLETE_SOCKET", id);
      // try {
      //    auth.refreshFlow(auth.provider);
      //    await api.axiosDelete(`/users/${id}`, id);
      //    router.push("/admin/users");
      //    this._vm.$socket.emit("DELETE_USER", id);
      //    commit("COMPLETE_SOCKET");
      //    commit("SET_ACTIVE_USER", null);
      //    createSuccess(`User: ${form.displayName} deleted`);
      //    return Promise.resolve("success");
      // } catch (err) {
      //    createError("Failed to delete", err);
      //    return Promise.reject(err);
      // }
   },
   startSetActiveUser({ commit, state }, id) {
      // check if ID exists in the users loaded
      const user = state.users.filter(item => {
         return item._id === id;
      })[0];
      if (user) {
         commit("SET_ACTIVE_USER", id);
         return Promise.resolve("success");
      } else {
         createError("Error", "No user found by the id provided.");
         return Promise.reject("no user found");
      }
   },
   startSetUserFilters({ commit }, form) {
      commit("SET_USER_FILTERS", form);
   },
   startUnsetUserFilters({ commit }) {
      const filters = {
         name: "",
         email: "",
         permission: [],
         isActive: "",
         bulkEnabled: "",
         sort: "a-z"
      }
      commit("SET_USER_FILTERS", filters);
   }
};

const mutations = {
   COMPLETE_SOCKET() {
      return
   },
   SET_USERS(state, users) {
      state.users = users;
   },
   SET_USER_BULK_ORDERS(state, bulk) {
      return state.userBulkOrders = bulk;
   },
   SOCKET_CREATE_USER(state, user) {
      if (state.users) {
         return state.users.push(user);
      }
      return state.users = [user];
   },
   SOCKET_UPDATE_USER(state, user) {
      if (!state.users) return;
      const index = state.users.findIndex(item => {
         return item._id === user._id;
      });
      if (index >= 0) {
         return state.users.splice(index, 1, user);
      }
      return state.users.push(user);
   },
   SOCKET_DELETE_USER(/*state, id*/) {
      return
      // const index = state.users.findIndex(item => {
      //    return item._id === id;
      // });
      // if (index >= 0) {
      //    return state.users.splice(index, 1);
      // }
   },
   SOCKET_CREATE_USER_SELF(state, user) {
      //check if users exist here (if they do then signed-in user should be an admin)
      if (!state.users) return;
      return state.users.push(user);
   },
   SOCKET_UPDATE_USER_SELF(state, user) {
      if (!state.users) return;
      const index = state.users.findIndex(item => {
         return item._id === user._id;
      });
      if (index >= 0) {
         return state.users.splice(index, 1, user);
      }
      return state.users.push(user);
   },
   SET_ACTIVE_USER(state, user) {
      return state.activeUser = user;
   },
   SET_USER_FILTERS(state, form) {
      state.filters.name = form.name;
      state.filters.email = form.email;
      state.filters.permission = form.permission;
      state.filters.isActive = form.isActive;
      state.filters.bulkEnabled = form.bulkEnabled;
      state.filters.sort = form.sort;
   }
};

export default {
   state,
   getters,
   actions,
   mutations
}