<template>
  <div class="modal flex__center">
    <div class="exit-save flex__col">
      <p class="text__md text__center text__bold">Exiting</p>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.exit-save {
  background-color: $maroon;
  padding: 15px;
  z-index: 25;
  width: 90%;
  max-width: 400px;
  border: 8px solid #fff;
  border-radius: 5px;
}
p {
  color: #fff;
}
</style>