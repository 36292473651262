// import router from "../../router";
import api from "../../api";
import { createSuccess, createError } from "../../libs/flash-messages";
import auth from "../../firebase/auth";

const state = {
   notificationShown: true,
   menuToggled: false,
   isSubmitting: false,
   loadingHeading: "Loading",
   notes: null,
   homeTiles: null,
   subLoading: false
};

const getters = {
   getNotificationShown(state) {
      return state.notificationShown;
   },
   getMenuToggled(state) {
      return state.menuToggled;
   },
   getIsSubmitting(state) {
      return state.isSubmitting;
   },
   getLoadingHeading(state) {
      return state.loadingHeading;
   },
   getNotes(state) {
      return state.notes;
   },
   getHomeTiles(state) {
      if (!state.homeTiles || state.homeTiles.length <= 0) return null;
      return state.homeTiles;
   },
   getSubLoading(state) {
      return state.subLoading;
   }
};

const actions = {
   startSetNotificationShown({ commit }) {
      commit("SET_NOTIFICATION_SHOWN", false)
   },
   startSetSubLoading({ commit }, bool) {
      commit("SET_SUB_LOADING", bool);
   },
   startSetIsSubmitting({ commit }, data) {
      commit("SET_IS_SUBMITTING", data.bool)
      data.heading ? commit("SET_LOADING_HEADING", data.heading) : commit("SET_LOADING_HEADING", "Loading")
   },
   async startSetNotes({ commit }) {
      try {
         const notes = await api.axiosGet("/public/notes");
         commit("SET_NOTES", notes[0]);
         return Promise.resolve("success");
      } catch (err) {
         createError("Notes failed", err);
         return Promise.reject(err);
      }
   },
   async startUpdateNotes({ commit }, form) {
      const id = form._id;
      try {
         await auth.refreshFlow(auth.provider);
         const notes = await api.axiosPut(`/notes/${id}`, form);
         this._vm.$socket.emit("UPDATE_NOTES", notes);
         commit("COMPLETE_SOCKET");
         createSuccess(`Notes updated`);
      } catch (err) {
         createError("Failed to update", err);
         return Promise.reject(err);
      }
   },
   async startSetHomeTiles({ commit }) {
      try {
         const homeTiles = await api.axiosGet("/public/home-tile");
         commit("SET_HOME_TILES", homeTiles);
         return Promise.resolve("success");
      } catch (err) {
         createError("Home Tile failed", err);
         return Promise.reject(err);
      }
   },
   async startCreateHomeTile({ commit }, form) {
      try {
         await auth.refreshFlow(auth.provider);
         const homeTile = await api.axiosPost("/home-tile", form);
         this._vm.$socket.emit("CREATE_HOME_TILE", homeTile);
         commit("COMPLETE_SOCKET");
         createSuccess("Tile Created");
      } catch (err) {
         createError("Failed to create", err);
         return Promise.reject(err);
      }
   },
   async startUpdateHomeTile({ commit }, form) {
      const id = form._id;
      try {
         await auth.refreshFlow(auth.provider);
         const homeTile = await api.axiosPut(`/home-tile/${id}`, form);
         this._vm.$socket.emit("UPDATE_HOME_TILE", homeTile);
         commit("COMPLETE_SOCKET");
         createSuccess(`Tile updated`);
      } catch (err) {
         createError("Failed to update", err);
         return Promise.reject(err);
      }
   }
};

const mutations = {
   COMPLETE_SOCKET() {
      return;
   },
   SET_NOTIFICATION_SHOWN(state, bool) {
      return state.notificationShown = bool
   },
   SET_SUB_LOADING(state, bool) {
      return state.subLoading = bool;
   },
   SET_IS_SUBMITTING(state, bool) {
      return state.isSubmitting = bool
   },
   SET_LOADING_HEADING(state, heading) {
      return state.loadingHeading = heading
   },
   SET_NOTES(state, notes) {
      return state.notes = notes;
   },
   SOCKET_UPDATE_NOTES(state, notes) {
      return state.notes = notes;
   },
   SET_HOME_TILES(state, info) {
      return state.homeTiles = info;
   },
   SOCKET_CREATE_HOME_TILE(state, info) {
      if (!state.homeTiles) {
         return state.homeTiles = [info];
      }
      return state.homeTiles.push(info);
   },
   SOCKET_UPDATE_HOME_TILE(state, info) {
      const index = state.homeTiles.findIndex(item => {
         return item._id === info._id;
      });
      if (index >= 0) {
         return state.homeTiles.splice(index, 1, info);
      }
      return state.homeTiles.push(info);
   }
};

export default {
   state,
   getters,
   actions,
   mutations
}