import Vue from 'vue';
import VueFlashMessage from 'vue-flash-message';
// eslint-disable-next-line
import flashStyles from 'vue-flash-message/dist/vue-flash-message.min.css';

Vue.use(VueFlashMessage, {
   messageOptions: {
      timeout: 5000,
      pauseOnInteract: true
   }
});