<template>
  <header>
    <!-- <StickyButton /> -->
    <div class="container header--grid" style="height:auto;">
      <router-link to="/" class="link__none">
        <img
          src="https://cdn.fbhl.com/FBHL/logos/100px-logo-fbhl.png"
          alt="WOW Logo"
          width="100%"
          height="100%"
          
        />
         <p class="flex__col">
          <span class="lg">WOW</span>
          <span class="sm">FBHL</span>
        </p>
      </router-link>
      <Search class="comp--search" />
      <Nav class="comp--nav" />
    </div>
  </header>
</template>

<script>
// import StickyButton from "./StickyButton";
import Nav from "../Navigation/Index";
import Search from "./Search";

export default {
  name: "Header",
  components: {
    // StickyButton,
    Nav,
    Search
  }
};
</script>

<style scoped lang="scss">
header {
  background-color: $dark-blue;
  position: sticky;
  top: 0;
  z-index: 15;
}
.header--grid {
  height: calc(#{$header-height-sm} - #{$sticky-height});
  @include grid($cols: 1fr 1fr, $rows: 1fr, $align: center);
  grid-template-areas: "logo nav";
}
.link__none {
  @include flex($just: flex-start, $align: center);
  img {
    background-color: #fff;
    
    
  }
  p {
    line-height: 1.2rem;
  }
  span.lg {
    margin-left: 5px;
    color: #fff;
    font-weight: bold;
    font-size: $text-lg;
  }
  span.sm {
    margin-left: 5px;
    color: $pistachio;
  }
}
/* .logo {
  grid-area: logo;
  height: 65px;
  width: 100px;
} */
.comp--search {
  grid-area: search;
  display: none;
}
.comp--nav {
  grid-area: nav;
  margin-left: auto;
}
@media (min-width: $md) {
  .header--grid {
    height: calc(#{$header-height-lg} - #{$sticky-height});
    @include flex($just: space-between, $align: center);
  }
  .comp--search {
    display: grid;
  }
  .comp--nav {
    margin: 0;
  }
}
</style>