import api from "../../api";
import cleanSQL from "../../api/cleanSQL";
import auth from "../../firebase/auth";
import { createSuccess, createError } from "../../libs/flash-messages";

const state = {
   filters: {
      products: [],
      status: [],
      categories: [],
      quantMin: null,
      quantMax: null,
      dateStart: null,
      dateEnd: null
   }
};

const getters = {
   // getInventory(state) {
   //    if (!state.inventory) return null;
   //    return state.inventory.sort((a, b) => {
   //       if (a.createdAt < b.createdAt) return 1;
   //       if (a.createdAt > b.createdAt) return -1;
   //       return 0;
   //    });
   // },
   getInventoryFilters(state) {
      return state.filters;
   },
   getFilteredInventoryProducts(state, getters, rootState) {
      if (!rootState.products.products) return null;
      const filters = state.filters;
      const products = rootState.products.products;
      return products
         .filter(product => {
            return product.isInventoried;
         })
         .filter(product => {
            if (filters.products.length <= 0) return product;
            return filters.products.includes(product._id);
         })
         .filter(product => {
            if (filters.status.length <= 0) return product;
            return filters.products.includes(product.status)
         })
         .filter(product => {
            if (filters.categories.length <= 0) return product;
            return product.categories.filter(cat => filters.categories.includes(cat)).some(val => val);
         })
         .filter(product => {
            if (!filters.quantMin) return product;
            return product.inventory >= filters.quantMin;
         })
         .filter(product => {
            if (!filters.quantMax) return product;
            return product.inventory <= filters.quantMax;
         });
   }
};

const actions = {
   // async startSetInventory({ commit }) {
   //    try {
   //       await auth.refreshFlow(auth.provider);
   //       const inventory = await api.axiosGet("/inventory");
   //       commit("SET_INVENTORY", inventory);
   //       return Promise.resolve("succes");
   //    } catch (err) {
   //       createError("Inventory failed", err);
   //       return Promise.reject(err);
   //    }
   // },
   async startGetCurrentInventory({ commit }) {
      try {
         await auth.refreshFlow(auth.provider);
         const inventory = await api.axiosGet("/inventory");
         commit("COMPLETE_INVENTORY");
         const addInv = inventory.map(row => {
            row.physical_count = ""
            return row;
         });
         return Promise.resolve(addInv);
      } catch (err) {
         createError("Couldn't get current inventory", err);
         return Promise.reject(err);
      }
   },
   async startGetInventoryOut({ commit }) {
      try {
         await auth.refreshFlow(auth.provider);
         const inventoryOut = await api.axiosGet("/inventory/out");
         commit("COMPLETE_INVENTORY");
         return Promise.resolve(inventoryOut);
      } catch (err) {
         createError("Couldn't get inventory out records", err);
         return Promise.reject(err);
      }
   },
   async startGetInventoryIn({ commit }) {
      try {
         await auth.refreshFlow(auth.provider);
         const inventoryIn = await api.axiosGet("/inventory/in");
         commit("COMPLETE_INVENTORY");
         return Promise.resolve(inventoryIn);
      } catch (err) {
         createError("Couldn't get inventory in records", err);
         return Promise.reject(err);
      }
   },
   async startGetInventoryDate({ commit }, date) {
      try {
         await auth.refreshFlow(auth.provider);
         const dateInv = await api.axiosGet(`/inventory/current/${date}`);
         commit("COMPLETE_INVENTORY");
         return Promise.resolve(dateInv);
      } catch (err) {
         createError("Couldn't get inventory from that date", err);
         return Promise.reject(err);
      }
   },
   async startGetInventoryTallyDate({ commit }, date) {
      try {
         await auth.refreshFlow(auth.provider);
         const results = await api.axiosGet(`/inventory/tally/${date}`);
         commit("COMPLETE_INVENTORY");
         return Promise.resolve(results);
      } catch (err) {
         createError("Couldn't get inventory tallies after that date", err);
         return Promise.reject(err);
      }
   },
   async startCreateInventory({ commit }, form) {
      try {
         await auth.refreshFlow(auth.provider);
         const cleanForm = cleanSQL(form);
         const item = await api.axiosPost(`/inventory`, cleanForm);
         const update = {
            _id: item.product_id,
            inventory: item.current_inventory
         }
         const product = await api.axiosPut(`/products/inventory/${update._id}`, update);
         this._vm.$socket.emit("UPDATE_PRODUCT", product);
         commit("COMPLETE_SOCKET");
         createSuccess(`Inventory for ${form.productName} added`);
         return Promise.resolve(item);
      } catch (err) {
         createError("Failed to add", err);
         return Promise.reject(err);
      }
   },
    async startUpdateInventory({ commit }, form) {
       const id = form._id;
       try {
          await auth.refreshFlow(auth.provider);
          const item = await api.axiosPut(`/inventory/${id}`, form);
          this._vm.$socket.emit("UPDATE_INVENTORY", item);
          commit("COMPLETE_SOCKET");
          createSuccess(`Inventory For: ${item.productName} updated`);
          return Promise.resolve("success");
       } catch (err) {
          createError("Failed to update", err);
          return Promise.reject(err);
       }
    },
   startSetInventoryFilters({ commit }, form) {
      commit("SET_INVENTORY_FILTERS", form);
   },
   startUnsetInventoryFilters({ commit }) {
      const filters = {
         products: [],
         status: [],
         categories: [],
         quantMin: null,
         quantMax: null,
         dateStart: null,
         dateEnd: null
      }
      commit("SET_INVENTORY_FILTERS", filters);
   }
};

const mutations = {
   COMPLETE_SOCKET() {
      return
   },
   COMPLETE_INVENTORY() {
      return;
   },
   // SET_INVENTORY(state, items) {
   //    return state.inventory = items;
   // },
   // SOCKET_CREATE_INVENTORY(state, item) {
   //    if (state.inventory) {
   //       return state.inventory.push(item);
   //    }
   //    return state.inventory = [item];
   // },
   // SOCKET_UPDATE_INVENTORY(state, item) {
   //    const index = state.inventory.findIndex(el => {
   //       return el._id === item._id;
   //    });
   //    if (index >= 0) {
   //       return state.inventory.splice(index, 1, item);
   //    }
   //    return state.inventory.push(item);
   // },
   SET_INVENTORY_FILTERS(state, form) {
      state.filters.products = form.products;
      state.filters.status = form.status;
      state.filters.categories = form.categories;
      state.filters.quantMin = form.quantMin;
      state.filters.quantMax = form.quantMax;
      state.filters.dateStart = form.dateStart;
      state.filters.dateEnd = form.dateEnd;
   }
};

export default {
   state,
   getters,
   actions,
   mutations
}