import moment from "moment";

const createOrderItems = (items) => {
   let list = "";
   items.forEach(item => {
      list = list + createOrderItem(item);
   });
   return list;
}
const createOrderItem = (item) => {
   return `
      <li>
         <span style="font-weight: bold;">${item.productName}</span>
         <br />
         <span>
            <span style="font-weight: bold;">Qty:</span>
            <span style="margin-left: 10px; font-weight: bold;">${item.quantity}</span>
         </span>
         <br />
         <span>
            <span>Unit Cost:</span>
            <span style="margin-left: 10px;">$${(item.loCost).toFixed(2)}</span>
         </span>
         <br />
         <span>
            <span>Total Cost:</span>
            <span style="margin-left: 10px;">$${(item.quantity * item.loCost).toFixed(2)}</span>
         </span>
         <br>
         <span style="font-weight: bold;">Customizations</span>
         <br>
         ${item.customizations && item.customizations.length > 0 ? createItemCust(item) : null}
      </li>
   `;
}

const createItemCust = (item) => {
   let list = "";
   let currCust;
   item.customizations.forEach(cust => {
      currCust = `
         <span style="font-weight: bold; font-style: italic; margin-left: 5px;"
            ${cust.name}
         </span>
         <br>
         <span>
            ${cust.option}
         </span>
         <br>
      `;
      list = list + currCust;
   });
   return list;
}

const showRespaWarning = (bool) => {
   if (bool) {
      return `         
      <tr>
         <td colspan="2" style="color: #fff; background-color: #b53522; padding: 3px; text-align: center;">Potential Respa Issue Present</td>
      </tr>
      `;
   }
   return;
}

export const bulkOrderEmail = (order, active, showRespaIssue) => {
   return `
   <p>This order has been received. We will begin processing it shortly.</p>
   <p>If we have any questions or we have completed the order, we will reach out with updates.</p>
   <br>
   <table style="cell-spacing: 0px; border-collapse: collapse; margin: auto; margin-bottom: 25px;">
      <tbody style="cell-padding: 0px">
         <tr>
            <td colspan="2" style="font-weight: bold; color: #fff; background-color: #264822; padding: 15px; font-size: 1rem; text-align: center;">Bulk WOW Request</td>
         </tr>
         <tr>
         <td colspan="2" style="font-weight: bold; color: #fff; background-color: #0b2838; padding: 5px 15px; font-size: 0.95rem;">Bulk Order Details</td>
         </tr>
         <tr style="border: 1px solid #aaa;">
            <td style="font-weight: bold; padding: 5px; width: 35%;">Bulk Requester Name</td>
            <td>${ active.requesterName}</td>
         </tr>
         <tr style="border: 1px solid #aaa; background-color: #a7d3d3;">
            <td style="font-weight: bold; padding: 5px; width: 35%;">Bulk Requester Email</td>
            <td>${ active.requesterEmail}</td>
         </tr>
         <tr style="border: 1px solid #aaa; background-color: #a7d3d3;">
            <td style="font-weight: bold; padding: 5px; width: 35%;">Bulk Requester Second Email</td>
            <td>${ active.requester2Email}</td>
         </tr>
         <tr style="border: 1px solid #aaa;">
            <td style="font-weight: bold; padding: 5px;">Bulk Order Comments</td>
            <td style="white-space: pre-wrap;">${ active.comments}</td>
         </tr>
         <tr style="border: 1px solid #aaa; background-color: #a7d3d3;">
            <td style="font-weight: bold; padding: 5px; width: 35%;">Use Award Balance</td>
            <td>${ active.useAwardBalance ? "Yes" : "No"}</td>
         </tr>
      </tbody>
   </table>
   
   <table style="cell-spacing: 0px; border-collapse: collapse; margin: auto;">
      <tbody style="cell-padding: 0px">
         <tr>
            <td colspan="2" style="font-weight: bold; color: #fff; background-color: #264822; padding: 15px; font-size: 1rem; text-align: center;">WOW Request for ${ order.shippingName} from ${order.requesterName}</td>
         </tr>
         <tr>
            <td colspan="2" style="font-weight: bold; color: #fff; background-color: #0b2838; padding: 5px 15px; font-size: 0.95rem;">Order Details</td>
         </tr>
         <tr style="border: 1px solid #aaa;">
            <td style="font-weight: bold; padding: 5px;">Created</td>
            <td>${ moment(order.createdAt).format("MM-DD-YYYY hh:mm a")}</td>
         </tr>
         <tr style="border: 1px solid #aaa; background-color: #a7d3d3;">
            <td style="font-weight: bold; padding: 5px; width: 35%;">Requester Name</td>
            <td>${ order.requesterName}</td>
         </tr>
         <tr style="border: 1px solid #aaa;">
            <td style="font-weight: bold; padding: 5px;">Requester Email</td>
            <td>${ order.requesterEmail}</td>
         </tr>
         <tr style="border: 1px solid #aaa; background-color: #a7d3d3;">
            <td style="font-weight: bold; padding: 5px;">Recipient Type</td>
            <td>${ order.recipientType}</td>
         </tr>
         <tr style="border: 1px solid #aaa;">
            <td style="font-weight: bold; padding: 5px;">${ order.recipientType === "Internal Employee" ? "Recipient Name" : "Borrower Name"}</td>
            <td>${ order.recipientName}</td>
         </tr>
         <tr style="border: 1px solid #aaa; background-color: #a7d3d3;">
            <td style="font-weight: bold; padding: 5px;">Co-Recipient Name</td>
            <td>${ order.coRecipientName}</td>
         </tr>
         // <tr style="border: 1px solid #aaa;">
         //    <td style="font-weight: bold; padding: 5px;">Loan Number</td>
         //    <td>${ order.loanNumber}</td>
         // </tr>
         <tr style="border: 1px solid #aaa; background-color: #a7d3d3;">
            <td style="font-weight: bold; padding: 5px;">Milestone</td>
            <td>${ order.milestone}</td>
         </tr>
         <tr style="border: 1px solid #aaa;">
            <td style="font-weight: bold; padding: 5px;">Comments</td>
            <td style="white-space: pre-wrap;">${ order.comments}</td>
         </tr>
         <tr>
            <td colspan="2" style="font-weight: bold; color: #fff; background-color: #0b2838; padding: 5px 15px; font-size: 0.95rem;">Product Details</td>
         </tr>
         ${showRespaWarning(showRespaIssue)}
         <tr style="border: 1px solid #aaa;">
            <td style="font-weight: bold; padding: 5px;">Total Cost</td>
            <td style="font-weight: bold;">$${ order.totalCost.toFixed(2)}</td>
         </tr>
         <tr style="border: 1px solid #aaa; background-color: #a7d3d3;">
            <td style="font-weight: bold; padding: 5px;">Order Items</td>
            <td>
               <ul>
               ${ createOrderItems(order.orderItems)}
               </ul>
            </td>
         </tr>
         <tr>
            <td colspan="2" style="font-weight: bold; color: #fff; background-color: #0b2838; padding: 5px 15px; font-size: 0.95rem;">Shipping Details</td>
         </tr>
         <tr style="border: 1px solid #aaa;">
            <td style="font-weight: bold; padding: 5px;">Shipping Name</td>
            <td>${ order.shippingName}</td>
         </tr>
         <tr style="border: 1px solid #aaa; background-color: #a7d3d3;">
            <td style="font-weight: bold; padding: 5px;">Shipping Address</td>
            <td>
               <span>${ order.shippingAddress.street}</span>
               <br>
               <span>${ order.shippingAddress.city}, ${order.shippingAddress.state}</span>
               <br>
               <span>${ order.shippingAddress.zip}</span>
            </td>
         </tr>
         <tr style="border: 1px solid #aaa;">
            <td style="font-weight: bold; padding: 5px;">Shipping Phone</td>
            <td>${ order.shippingPhone}</td>
         </tr>
         <tr style="border: 1px solid #aaa; background-color: #a7d3d3;">
            <td style="font-weight: bold; padding: 5px;">Thank You Note</td>
            <td style="white-space: pre-wrap;">${ order.thankYouNote ? order.thankYouNote : ""}</td>
         </tr>
         <tr style="border: 1px solid #aaa;">
            <td style="font-weight: bold; padding: 5px;">Delivery Date</td>
            <td>${ order.deliveryDate ? order.deliveryDate : "none provided"}</td>
         </tr>
         <tr>
            <td colspan="2" style="font-weight: bold; color: #fff; background-color: #0b2838; padding: 5px 15px; font-size: 0.95rem;">Billing Details</td>
         </tr>
         <tr style="border: 1px solid #aaa;">
            <td style="font-weight: bold; padding: 5px;">Billing User</td>
            <td>${ order.billingName}</td>
         </tr>
         <tr style="border: 1px solid #aaa; background-color: #a7d3d3;">
            <td style="font-weight: bold; padding: 5px;">Billing Email</td>
            <td>${ order.billingEmail}</td>
         </tr>
         <tr style="border: 1px solid #aaa;">
            <td style="font-weight: bold; padding: 5px;">Billing method</td>
            <td>${ order.billingMethod}</td>
         </tr>
         <tr style="border: 1px solid #aaa; background-color: #a7d3d3;">
            <td style="font-weight: bold; padding: 5px;">Use Award Balance</td>
            <td>${ order.useAwardBalance ? "Yes" : "No"}</td>
         </tr>
         <tr style="border: 1px solid #aaa;">
            <td style="font-weight: bold; padding: 5px;">Award Amount Available</td>
            <td>${ order.awardAmountUsed.toFixed(2)}</td>
         </tr>
         <tr style="border: 1px solid #aaa; background-color: #a7d3d3;">
            <td style="font-weight: bold; padding: 5px;">Remaining Balance</td>
            <td><span style="font-weight: bold;">$${ order.balanceDue.toFixed(2)}</span> = ${order.totalCost.toFixed(2)} - ${order.awardAmountUsed.toFixed(2)}</td>
         </tr>
         <tr style="border: 1px solid #aaa;">
            <td style="font-weight: bold; padding: 2px;"></td>
            <td>remaining balance cannot drop below $0.00</td>
         </tr>
         <tr>
            <td colspan="2" style="text-align: center; background-color: #264822; font-size: 0.95rem; font-weight: bold; cursor: pointer;">
               <a href="https://wow.fbhl.com/past-order/${order._id}/order-confirm" target="_blank" style="padding: 5px; display: block; color: #eaf4e5;">View Order</a>
            </td>
         </tr>
      </tbody>
   </table> 
   `;
}