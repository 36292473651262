// import Pages from "../components/Pages";
import store from "../store";
import { checkLogin } from "./libs/helpers";

const loadView = view => {
   return () => import(/* webpackChunkName: "set-account" */ `../components/Pages/${view}/Index.vue`)
}

export default [
   {
      path: "/my-account/create",
      name: "account-create",
      component: loadView("NewUser"),
      beforeEnter(to, from, next) {
         if (store.getters.getIsLoggedIn) {
            return next("/");
         }
         if (!store.state.user.token) {
            return next("/");
         }
         return next();
      }
   },
   {
      path: "/my-account/login-issue",
      name: "login-issue",
      component: loadView("UpdateAccount"),
      beforeEnter: (to, from, next) => {
         if (!store.state.user.token) {
            next("/");
         }
         next();
      }
   },
   {
      path: "/my-account",
      name: "account",
      component: loadView("Account"),
      beforeEnter: async (to, from, next) => {
         checkLogin(to, from, next, false);
         try {
            await store.dispatch("startSetBulkOrders", true);
            next();
         } catch (err) {
            console.log(err);
            next();
         }
      }
   },
   {
      path: "/my-account/past-orders",
      name: "my-account-past-orders",
      component: loadView("PastOrders"),
      beforeEnter: async (to, from, next) => {
         checkLogin(to, from, next, false);
         try {
            store.dispatch("startSetIsAccount", true);
            if (!store.getters.getUserOrders) await store.dispatch("startSetUserOrders", to.params.user);
            next();
         } catch (err) {
            next("/admin/users");
         }
      }
   }
   // {
   //    path: "/my-account/favorites",
   //    name: "favorites",
   //    component: loadView("Favorites"),
   //    beforeEnter: (to, from, next) => {
   //       checkLogin(to, from, next, false);
   //       //dispatch store to get favorites
   //       next();
   //    }
   // },
   // {
   //    path: "/my-account/favorites/new",
   //    name: "favorites-new",
   //    component: loadView("Favorite")
   // },
   // {
   //    path: "/my-account/favorites/:id",
   //    name: "favorite-edit",
   //    component: loadView("Favorite")
   // }
]