import firebase from 'firebase/app';
import 'firebase/auth';
import fbConfig from "./config";
import store from "../store";
import router from "../router";

export const VALID_DOMAINS = ["fbhl.com"];

export const isValidDomain = (email) => {
   const domain = email.split("@")[1];
   return VALID_DOMAINS.includes(domain);
}

firebase.initializeApp(fbConfig);

const authFlow = async (res) => {
   try {
      const token = await firebase.auth().currentUser.getIdToken();
      const user = res.additionalUserInfo.profile;
      user.token = token;
      localStorage.setItem("user", `${JSON.stringify(user)}`);
      await store.dispatch("setUpUser", user);
      return Promise.resolve("success");
   } catch (err) {
      return Promise.reject(err);
   }
}

const provider = new firebase.auth.OAuthProvider("microsoft.com");

const createCustParameters = (provider, type) => {
   provider.setCustomParameters({
      tenant: "9b9c2d4d-bfe5-4326-a44b-e0d91ff34837",
      prompt: type
   });
}

const signIn = async (provider, shouldRoute = true) => {
   try {
      createCustParameters(provider, "login");
      const res = await firebase.auth().signInWithPopup(provider);
      await authFlow(res);
      if (!shouldRoute) {
         return Promise.resolve("success");
      }
      if (store.getters.getIsLoggedIn) {
         return router.push("/");
      }
      return Promise.resolve("success");
   } catch (err) {
      console.log(err);
      // store.dispatch("logout");
      return Promise.reject("No user logged in")
   }
}

const signInNoRedirect = async (provider) => {
   try {
      createCustParameters(provider, "login");
      const res = await firebase.auth().signInWithPopup(provider);
      await authFlow(res);
      if (store.getters.getIsLoggedIn) {
         return Promise.resolve("success");
      }
      return router.push("/my-account/create");
   } catch (err) {
      console.log(err);
      store.dispatch("logout");
      return Promise.reject("No user logged in")
   }
}

const checkUser = () => {
   if (localStorage.getItem("user")) {
      return JSON.parse(localStorage.getItem("user"))
   } else {
      return null;
   }
}

// used as a pre-auth for CRUD requests to API
// if token is valid then request gets sent
// if not valid then asked to sign in again and get fresh token, then request gets sent
// if sign in fails then logout
const refreshFlow = async (provider, newUser = false) => {
   // check if user exists
   if (!localStorage.getItem("user")) {
      store.dispatch("logout");
      return Promise.reject("No user signed in");
   }
   try {
      // verify token is still valid
      if (newUser) {
         const res = await store.dispatch("checkToken");
         return Promise.resolve(res);
      } else {
         const res = await store.dispatch("checkUser");
         return Promise.resolve(res);
      }
   } catch (err) {
      // attempt to refresh token
      console.log(err);
      if (err === "Login token expired. Please sign in again to continue.") {
         try {
            createCustParameters(provider, "select_account");
            const res = await firebase.auth().signInWithPopup(provider);
            await authFlow(res);
            return Promise.resolve("success");
         } catch (err) {
            await store.dispatch("logout");
            return Promise.reject("failed to sign back in");
         }
      } else {
         store.dispatch("logout");
         return Promise.reject(err);
      }
   }
};

const catchExpire = async (provider, err) => {
   if (err === "Login token expired. Please sign in again to continue.") {
      try {
         createCustParameters(provider, "select_account");
         const res = await firebase.auth().signInWithPopup(provider);
         await authFlow(res);
         return Promise.resolve("success");
      } catch (err) {
         await store.dispatch("logout");
         return Promise.reject("failed to sign back in");
      }
   } else {
      store.dispatch("logout");
      return Promise.reject(err);
   }
}

const signOut = async () => {
   try {
      await firebase.auth().signOut();
      localStorage.removeItem("user");
      window.localStorage.removeItem("socket-details");
      return Promise.resolve("success");
   } catch (err) {
      return Promise.reject(err);
   }
}

export default {
   provider,
   signIn,
   signInNoRedirect,
   checkUser,
   refreshFlow,
   catchExpire,
   signOut
}