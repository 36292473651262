import Vue from 'vue';
import Vuex from 'vuex';

//modules
import user from "./modules/user";
import site from "./modules/site";
import categories from "./modules/categories";
import products from "./modules/products";
import users from "./modules/users";
import inventory from "./modules/inventory";
import orders from "./modules/orders";
import bulk from "./modules/bulk";
import tickets from "./modules/tickets";

Vue.use(Vuex);

export default new Vuex.Store({
   modules: {
      site,
      user,
      categories,
      products,
      users,
      inventory,
      orders,
      bulk,
      tickets
   }
});