// import Pages from "../components/Pages";
import store from "../store";
import { checkAccess } from "./libs/helpers";

const loadView = view => {
   return () => import(/* webpackChunkName: "set-order" */ `../components/Pages/${view}/Index.vue`)
}

export default [
   {
      path: "/past-orders",
      name: "past-orders-redirect",
      beforeEnter: (to, from, next) => {
         const access = ["admin", "super-admin"];
         if (access.includes(store.getters.getUserDetails.permission)) {
            return next("/admin/past-orders")
         }
         return next("/my-account/past-orders");
      }
   },
   {
      path: "/past-order/:order/products",
      name: "past-order-products",
      component: loadView("Products"),
      beforeEnter: async (to, from, next) => {
         checkAccess(null, to, from, next, false);
         try {
            await store.dispatch("startSetActiveOrder", to.params.order);
            next()
         } catch (err) {
            return next(from.fullPath);
         }
      }
   },
   {
      path: "/past-order/:order/products/:product",
      name: "past-order-product",
      component: loadView("Product"),
      beforeEnter: async (to, from, next) => {
         checkAccess(null, to, from, next, false);
         try {
            await store.dispatch("startSetActiveOrder", to.params.order);
            await store.dispatch("startSetActiveProduct", to.params.product);
            next();
         } catch (err) {
            return next(from.fullPath)
         }
      }
   },
   {
      path: "/past-order/:order/order-confirm",
      name: "past-order-confirm",
      component: loadView("OrderConfirm"),
      beforeEnter: async (to, from, next) => {
         checkAccess(null, to, from, next, false);
         try {
            await store.dispatch("startSetActiveOrder", to.params.order);
            next()
         } catch (err) {
            return next(from.fullPath);
         }
      }
   }
]