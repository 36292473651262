<template>
  <section>
    <ul class="footer-main--links">
      <router-link to="/">
        <li>Home</li>
      </router-link>
      <router-link to="/new-order">
        <li>New Order</li>
      </router-link>
      <router-link to="/about">
        <li>About Us</li>
      </router-link>
      <router-link to="/products">
        <li>Products</li>
      </router-link>
    </ul>
    <section class="flex__center footer-main--auth">
      <p
        class="text__bold mr-10 text__powder"
      >{{ getUser.isLoggedIn ? `Hi, ${getUserDetails.name.first}` : "Welcome to WOW" }}</p>
      <p v-if="getUser.isLoggedIn" class="ml-10 text__pistachio pointer" @click="navLogout">
        Sign Out
        <font-awesome-icon icon="sign-out-alt" size="sm" />
      </p>
      <p v-else @click="signIn" class="ml-10 text__pistachio pointer">
        Sign In
        <font-awesome-icon icon="sign-out-alt" size="sm" />
      </p>
    </section>
    <p class="text__center text__powder mb-15 text__sm">
      Trouble signing in? Please fill out the form
      <router-link to="/my-account/login-issue" class="link__none text__bold text__green">here</router-link>.
    </p>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import auth from "../../../firebase/auth";
export default {
  computed: {
    ...mapGetters(["getUser", "getUserDetails"])
  },
  methods: {
    ...mapActions(["logout"]),
    signIn() {
      auth.signIn(auth.provider);
    },
    async navLogout() {
      try {
        await this.logout();
        this.toggleNav();
      } catch (err) {
        console.log(err);
      }
    },
    toggleNav() {
      this.$emit("toggleNav", false);
    }
  }
};
</script>

<style scoped lang="scss">
.footer-main--links {
  @include grid($cols: 1fr, $row-gap: 8px);
  a {
    padding: 5px;
    font-weight: bold;
    text-decoration: none;
    text-align: center;
    font-size: $text-md;
  }
  a li {
    color: $pistachio;
  }
}
.footer-main--auth {
  margin-top: 10px;
}
@media (min-width: $sm) {
  .footer-main--links {
    @include flex($just: center, $align: center, $wrap: wrap);
    a {
      width: 120px;
    }
  }
}
</style>