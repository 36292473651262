<template>
  <div class="header--search search--bar">
    <input
      type="text"
      placeholder="product search"
      v-model="form.search"
      @keydown.enter="submitSearch"
    />
    <div class="btn__search" @click="submitSearch">
      <font-awesome-icon icon="search" size="lg" class="icon" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "ProductSearch",
  data() {
    return {
      form: {
        search: ""
      }
    };
  },
  computed: {
    ...mapGetters(["getProductFilters"])
  },
  methods: {
    ...mapActions(["startSetProductFilters"]),
    submitSearch() {
      const copyFilters = JSON.parse(JSON.stringify(this.getProductFilters));
      copyFilters.name = this.form.search;
      this.startSetProductFilters(copyFilters);
      this.$emit("toggleNav", false);
      let route;
      if (
        this.$route.fullPath.match(
          /\/new-order\/[a-zA-z0-9]{1,}\/order-confirm/g
        )
      ) {
        route = this.$route.fullPath.replace(/\/order-confirm/g, "/products");
        return this.$router.push(route);
      }
      if (
        this.$route.fullPath.match(
          /\/past-order\/[a-zA-z0-9]{1,}\/order-confirm/g
        )
      ) {
        return this.$router.push("/products");
      }
      if (
        this.$route.fullPath.match(
          /\/past-order\/[a-zA-Z0-9]{1,}\/products\/[a-zA-Z0-9?=/]{1,}/g
        ) ||
        this.$route.fullPath.match(
          /\/new-order\/[a-zA-Z0-9]{1,}\/products\/[a-zA-Z0-9?=/]{1,}/g
        )
      ) {
        console.log("testing");
        route = this.$route.fullPath.replace(
          /\/products\/[a-zA-Z0-9=?/]{1,}/g,
          "/products"
        );
        return this.$router.push(route);
      }
      if (
        this.$route.fullPath.match(/\/new-order\/[a-zA-z0-9]{1,}\/products/g) ||
        this.$route.fullPath.match(/\/past-order\/[a-zA-z0-9]{1,}\/products/g)
      ) {
        return;
      }
      const path = this.$router.currentRoute.path;
      // emit to store to filter products dislayed
      if (path !== "/products") {
        this.$router.push("/products");
      }
    }
  }
};
</script>

<style scoped lang="scss">
@media (min-width: $md) {
  .header--search {
    width: 75%;
    margin: 0 15px;
    max-width: 750px;
  }
}
</style>