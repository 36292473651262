<template>
  <div class="modal flex__center">
    <div class="modal--loading">
      <p class="text__lg text__center text__bold">{{ getLoadingHeading }}</p>
      <div class="dots">
        <div class="dot dot-1"></div>
        <div class="dot dot-2"></div>
        <div class="dot dot-3"></div>
        <div class="dot dot-4"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    heading: {
      type: String,
      default: "Loading"
    }
  },
  computed: {
    ...mapGetters(["getLoadingHeading"])
  }
};
</script>

<style scoped lang="scss">
.modal {
  @include pos-abs($pos: fixed, $width: 100vw, $height: 100vh, $z: 1000);
  background-color: $black-trans;
}

.modal--loading {
  padding: 50px;
  background-color: #fff;
  border-radius: 8px;
  @include flex($dir: column, $just: space-between, $align: center);
}
.modal--loading p {
  margin-bottom: 50px;
}
.dot {
  display: inline-block;
  width: 15px;
  height: 15px;
  background-color: $green;
  border-radius: 50%;
  margin: 5px;
  animation: 1s ease-in-out infinite;
}
@for $i from 1 through 4 {
  .dot-#{$i} {
    animation-name: rolling#{$i};
  }
}

@keyframes rolling1 {
  0% {
    transform: translateY(0px);
    background-color: $green;
  }

  20% {
    transform: translateY(-30px);
    background-color: $maroon;
  }

  40% {
    transform: translateY(0px);
  }

  100% {
    transform: translateY(0px);
  }
}

@keyframes rolling2 {
  0% {
    transform: translateY(0px);
  }

  10% {
    transform: translateY(0px);
    background-color: $green;
  }

  35% {
    transform: translateY(-30px);
    background-color: $maroon;
  }

  60% {
    transform: translateY(0px);
  }

  100% {
    transform: translateY(0px);
  }
}

@keyframes rolling3 {
  0% {
    transform: translateY(0px);
  }

  25% {
    transform: translateY(0px);
    background-color: $green;
  }

  50% {
    transform: translateY(-30px);
    background-color: $maroon;
  }

  75% {
    transform: translateY(0px);
  }

  100% {
    transform: translateY(0px);
  }
}

@keyframes rolling4 {
  0% {
    transform: translateY(0px);
  }

  40% {
    transform: translateY(0px);
    background-color: $green;
  }

  65% {
    transform: translateY(-30px);
    background-color: $maroon;
  }

  85% {
    transform: translateY(0px);
  }

  100% {
    transform: translateY(0px);
  }
}
</style>