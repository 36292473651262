import auth from "../../firebase/auth";
import store from "../../store";
import { createError } from "../../libs/flash-messages";

/*
*  checkLogin: checks if a user is signed in, then proceeds to route
**
*  @to, @from, @next: middleware functions from vue-router
*/

export const checkLogin = async (to, from, next, shouldCallNext) => {
   shouldCallNext = shouldCallNext === undefined ? true : false;
   if (!store.getters.getIsLoggedIn) {
      createError("Login", "Please login to access the requested page");
      try {
         await auth.signInNoRedirect(auth.provider);
         return shouldCallNext ? next() : Promise.resolve("success");
      } catch (err) {
         return next("/");
      }
   }
   return shouldCallNext ? next() : null;
}

/*
*  checkAccess: checks if a user is signed in, then, if permissions are passed, determines if they have access
**
*  @access: array of permissions which are allowed for this route
*  @to, @from, @next: middleware functions from vue-router
*  @shouldCallNext: determines if the middleware is completed or more is needed at the router
*/

export const checkAccess = async (access, to, from, next, shouldCallNext) => {
   access = !access ? ["user", "admin", "super-admin", "management", "asm"] : access;
   if (!store.getters.getIsLoggedIn) {
      createError("Login", "Please login to access the requested page");
      try {
         await auth.signInNoRedirect(auth.provider);
         return shouldCallNext ? next() : null;
      } catch (err) {
         return next("/")
      }
   }
   if (access) {
      if (access.includes(store.getters.getUserDetails.permission)) return shouldCallNext ? next() : null;
      createError("Access Denied", "You do not have sufficient permission to view this page");
      next(from.fullPath);
   } else {
      return shouldCallNext ? next() : null;
   }
}

/*
*  superAccess: checks if a user is signed in, then checks if access is super-admin, this replaces the (to, from, next) anonymous function and should call next() afterward
**
*  @access: array of permissions which are allowed for this route
*  @to, @from, @next: middleware functions from vue-router
*/

export const superAccess = async (to, from, next) => {
   if (!store.getters.getIsLoggedIn) {
      createError("Login", "Please login to access the requested page");
      try {
         await auth.signInNoRedirect(auth.provider);
         return next();
      } catch (err) {
         return next("/")
      }
   }
   if (store.getters.getUserDetails.permission === "super-admin") return next();
   createError("Access Denied", "You do not have sufficient permission to view this page");
   next(from.fullPath);
}

/*
*  adminAccess: checks if a user is signed in, then checks if access is super-admin or admin, this replaces the (to, from, next) anonymous function and should call next() afterward
**
*  @access: array of permissions which are allowed for this route
*  @to, @from, @next: middleware functions from vue-router
*/

export const adminAccess = async (to, from, next) => {
   const access = ["admin", "super-admin"];
   if (!store.getters.getIsLoggedIn) {
      createError("Login", "Please login to access the requested page");
      try {
         await auth.signInNoRedirect(auth.provider);
         return next();
      } catch (err) {
         return next("/")
      }
   }
   if (access.includes(store.getters.getUserDetails.permission)) return next();
   createError("Access Denied", "You do not have sufficient permission to view this page");
   next(from.fullPath);
}


export const checkBulkAccess = async (to, from, next) => {
   if (!store.getters.getIsLoggedIn) {
      createError("Login", "Please login to access the requested page");
      try {
         await auth.signInNoRedirect(auth.provider);
         return;
      } catch (err) {
         return next("/")
      }
   }
   if (!store.getters.getUserDetails.bulkEnabled) {
      createError("Batch Disabled", "Please contact the WOW department to gain access to the batch order form");
      return next("/contact-us?topic=bulk");
   }
   return;
}

export const checkLeave = (to, from, next) => {
   const answer = window.confirm("You are about to leave this order.\nAre you sure you want to move on?");
   if (answer) {
      next();
   } else {
      next(false);
   }
}