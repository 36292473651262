import auth from "../../firebase/auth";
import router from "../../router";
import api from "../../api";
import { createError, createSuccess } from "../../libs/flash-messages";

const state = {
   token: null,
   email: null,
   username: null,
   userDetails: null,
   isLoggedIn: false
};

const getters = {
   getUser(state) {
      return state;
   },
   getUserDetails(state) {
      return state.userDetails;
   },
   getIsLoggedIn(state) {
      return state.isLoggedIn;
   },
   getToken(state) {
      return state.token;
   }
};

const actions = {
   async setUpUser({ commit }, data) {
      const token = data.token;
      try {
         const user = await api.axiosPostLog("/login", token);
         if (user && user._id) {
            //set this up for selective subscription to socket updates
            window.localStorage.setItem("socket-details", `${JSON.stringify({ _id: user._id, permission: user.permission })}`);
            commit("SET_TOKEN", token);
            commit("SET_USER_DETAILS", user);
            commit("SET_USERNAME", user.displayName);
            commit("SET_EMAIL", user.email);
            commit("SET_IS_LOGGED_IN", true);
            return Promise.resolve("success");
         }
      } catch (err) {
         if (JSON.stringify(err).includes("No user found for ")) {
            commit("SET_TOKEN", data.token);
            commit("SET_USERNAME", data.displayName);
            commit("SET_EMAIL", data.mail);
            createError("Couldn't sign you in", `No account found for ${data.mail}. Create an account or contact WOW for help.`);
            router.push("/my-account/create");
            return Promise.resolve("success");
         }
         createError("Login Issue", err);
         await auth.refreshFlow(auth.provider);
      }
   },
   async startCreateSelf({ commit, state }, data) {
      try {
         await auth.refreshFlow(auth.provider, true);
         const user = await api.axiosPost("/public/new-user", data);
         this._vm.$socket.emit("CREATE_USER_SELF", user);
         const token = state.token;
         window.localStorage.setItem("socket-details", `${JSON.stringify({ _id: user._id, permission: user.permission })}`);
         commit("SET_TOKEN", token);
         commit("SET_USER_DETAILS", user);
         commit("SET_USERNAME", user.displayName);
         commit("SET_EMAIL", user.email);
         commit("SET_IS_LOGGED_IN", true);
         createSuccess("Account created!");
         return Promise.resolve("success");
      } catch (err) {
         createError("Failed to create account", err);
         return Promise.reject(err);
      }
   },
   async checkToken({ state }) {
      const token = state.token;
      try {
         // check if token is still valid
         await api.axiosPostLog("/check-token", token);
         return Promise.resolve("token verified");
      } catch (err) {
         return Promise.reject(err);
      }
   },
   async checkUser({ state }) {
      const token = state.token;
      try {
         // check if token is still valid and user exists
         await api.axiosPostLog("/verify", token);
         return Promise.resolve("token verified");
      } catch (err) {
         return Promise.reject(err);
      }
   },
   async startUpdateSelf({ commit }, form) {
      const id = form._id;
      try {
         await auth.refreshFlow(auth.provider);
         const user = await api.axiosPut(`/public/user/${id}`, form);
         this._vm.$socket.emit("UPDATE_USER_SELF", user);
         commit("SET_USER_DETAILS", user);
         commit("SET_USERNAME", user.displayName);
         createSuccess(`Account updated`);
      } catch (err) {
         createError("Failed to update", err);
         return Promise.reject(err);
      }
   },
   async logout({ commit }) {
      try {
         const status = await auth.signOut();
         if (status === "success") {
            commit("SET_TOKEN", null);
            commit("SET_USER_DETAILS", null);
            commit("SET_USERNAME", null);
            commit("SET_EMAIL", null);
            commit("SET_IS_LOGGED_IN", false);
            if (router.currentRoute.path !== "") {
               router.push("/");
            }
            createSuccess("Successfully Logged Out")
            return Promise.resolve("success");
         }
      } catch (err) {
         return Promise.reject(err);
      }
   },
   async loadDelegates() {
      try {
         const users = await api.axiosGet(`/public/users/delegates`);
         return Promise.resolve(users);
      } catch (err) {
         createError("Delegates not fetched", err);
         return Promise.reject(err);
      }
   }
};

const mutations = {
   SET_USER_DETAILS(state, user) {
      state.userDetails = user;
   },
   SET_TOKEN(state, token) {
      state.token = token;
   },
   SET_USER_ID(state, id) {
      state.userId = id;
   },
   SET_USERNAME(state, name) {
      state.username = name;
   },
   SET_FIRST_NAME(state, name) {
      state.firstName = name;
   },
   SET_LAST_NAME(state, name) {
      state.lastName = name;
   },
   SET_EMAIL(state, email) {
      state.email = email;
   },
   SET_PHONE(state, phone) {
      state.phone = phone;
   },
   SET_PERMISSION(state, permission) {
      state.permission = permission;
   },
   SET_IS_LOGGED_IN(state, bool) {
      state.isLoggedIn = bool;
   },
   SOCKET_UPDATE_USER_ADMIN(state, user) {
      if (state.userDetails._id === user._id) {
         state.userDetails = user;
      }
   }
};

export default {
   state,
   getters,
   actions,
   mutations
}