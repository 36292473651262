<template>
  <div class="nav--menu" @click.self="toggleNav">
    <nav>
      <div class="flex__between flex__reverse">
        <div class="icon pointer" @click="toggleNav">
          <font-awesome-icon icon="times" size="2x" class="text__white" />
        </div>
        <div class="nav--menu--search">
          <Search @toggleNav="toggleNav" />
        </div>
      </div>
      <simplebar class="navigation--overflow" data-simplebar-auto-hide="false">
        <p class="flex__between nav--menu--auth">
          <span
            class="text__bold text__powder text__md"
          >{{ getUserDetails ? `Hi, ${getUserDetails.name.first}` : "Welcome to WOW" }}</span>
          <span v-if="getUser.isLoggedIn" class="text__pistachio pointer" @click="navLogout">
            Sign Out
            <font-awesome-icon icon="sign-out-alt" size="1x" class="ml-10" />
          </span>
          <span v-else @click="signIn" class="text__pistachio pointer">
            Sign In
            <font-awesome-icon icon="sign-out-alt" size="sm" />
          </span>
        </p>
        <p class="text__center text__powder mb-15 text__sm">
          Trouble signing in? Please fill out the form
          <router-link
            to="/my-account/login-issue"
            class="link__none text__bold text__pistachio"
          >here</router-link>.
        </p>
        <ul class="nav--list">
          <router-link
            to="/new-order/order-details"
            class="nav--list-item text__center"
            @click.native="toggleNav"
          >
            <li>New Order</li>
          </router-link>
          <router-link
            to="/batch-order/order-details"
            class="nav--list-item text__center"
            @click.native="toggleNav"
          >
            <li>Batch Order</li>
          </router-link>
          <router-link to="/products" class="nav--list-item text__center" @click.native="toggleNav">
            <li>Products</li>
          </router-link>
          <router-link
            to="/my-account"
            class="nav--list-item text__center"
            @click.native="toggleNav"
          >
            <li>My Account</li>
          </router-link>
          <router-link to="/help" class="nav--list-item text__center" @click.native="toggleNav">
            <li>FAQ</li>
          </router-link>
          <router-link to="/about" class="nav--list-item text__center" @click.native="toggleNav">
            <li>About Us</li>
          </router-link>

          <router-link
            v-if="showAdmin"
            to="/admin"
            class="nav--list-item text__center"
            @click.native="toggleNav"
          >
            <li>Admin</li>
          </router-link>
          <router-link
            v-if="showAdmin"
            to="/admin/past-orders?status=incomplete"
            class="nav--list-item text__center"
            @click.native="toggleNav"
          >
            <li>All Orders</li>
          </router-link>
          <router-link
            v-if="showAdmin"
            to="/admin/products"
            class="nav--list-item text__center"
            @click.native="toggleNav"
          >
            <li>All Products</li>
          </router-link>
          
          <router-link
            v-if="showAdmin"
            to="/admin/users"
            class="nav--list-item text__center"
            @click.native="toggleNav"
          >
            <li>All Users</li>
          </router-link>
          <router-link
            v-if="showReporting"
            to="/admin/reporting"
            class="nav--list-item text__center"
            @click.native="toggleNav"
          >
            <li>Reporting</li>
          </router-link>
        </ul>
      </simplebar>
    </nav>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import auth from "../../../firebase/auth";
import Search from "../Header/Search";
export default {
  name: "NavList",
  components: {
    Search
  },
  data() {
    return {
      access: ["admin", "super-admin", "management"]
    };
  },
  computed: {
    ...mapGetters(["getUser", "getUserDetails"]),
    showAdmin() {
      const access = ["admin", "super-admin"];
      return (
        this.getUserDetails && access.includes(this.getUserDetails.permission)
      );
    },
    showReporting() {
      const access = ["admin", "super-admin", "management"];
      return (
        this.getUserDetails && access.includes(this.getUserDetails.permission)
      );
    }
  },
  methods: {
    ...mapActions(["logout"]),
    signIn() {
      auth.signIn(auth.provider);
      this.toggleNav();
    },
    async navLogout() {
      try {
        await this.logout();
        this.toggleNav();
      } catch (err) {
        console.log(err);
      }
    },
    toggleNav() {
      this.$emit("toggleNav", false);
    }
  }
};
</script>

<style scoped lang="scss">
.nav--menu {
  @include pos-abs($pos: fixed, $width: 100vw, $height: 100vh, $z: 15);
  background-color: $black-trans;
}
nav {
  max-width: 400px;
  margin-left: auto;
  height: 100%;
  background-color: $peacock;
}
.icon {
  padding: 5px 15px;
}
.nav--menu--search {
  margin: 10px 15px;
}
.nav--menu--auth {
  margin: 15px;
}
.nav--list {
  padding: 4px 0;
  background: linear-gradient(to right, $peacock, $pistachio, $peacock);
  @include grid($cols: 1fr, $row-gap: 4px);
}
.nav--list-item {
  color: #fff;
  font-size: 1.8rem;
  padding: 20px 0;
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;
  @include grid($cols: 1fr);
  background-color: $peacock;
  li {
    color: $powder;
  }
  &:hover {
    li {
      color: $pistachio;
    }
  }
}
.router-link-exact-active li {
  color: $pistachio;
}
@media (min-width: $md) {
  .nav--menu--search {
    display: none;
  }
}
</style>