// import Pages from "../components/Pages";
import store from "../store";

const loadView = view => {
   return () => import(/* webpackChunkName: "set-products" */ `../components/Pages/${view}/Index.vue`)
}

export default [
   {
      path: "/products",
      name: "products",
      component: loadView("Products")
   },
   {
      path: "/products/:product",
      name: "product",
      component: loadView("Product"),
      beforeEnter: async (to, from, next) => {
         try {
            await store.dispatch("startSetActiveProduct", to.params.product)
            next();
         } catch (err) {
            next("/products");
         }
      }
   }
]