<template>
  <div class="account--message">
    <div class="account--message-border">
      <h3 class="text__center">Existing Message</h3>
      <simplebar class="message--overflow" data-simplebar-auto-hide="false">
        <div class="account--message-content">
          <label>
            Name{{ editing ? "*" : null }}
            <p v-if="!editing" class="fix-44 flex__col">{{ option.name }}</p>
            <input v-else type="text" v-model="response.name" />
          </label>
          <label>
            Message{{ editing ? "*" : null }}
            <p v-if="!editing" class="pre-wrap fix-147">{{ option.message }}</p>
            <textarea v-else v-model="response.message" rows="5" />
          </label>
        </div>
      </simplebar>
      <div v-if="!editing && !deleting" class="dual--buttons">
        <div class="btn__red" @click="toggleDeleting">
          <font-awesome-icon icon="times" size="1x" class="mr-10" />Delete
        </div>
        <div class="btn__green btn__confirm pointer" @click="toggleEditing">
          <font-awesome-icon icon="edit" size="1x" class="mr-10" />Edit
        </div>
      </div>
      <div v-if="editing" class="dual--buttons">
        <div class="btn__red" @click="toggleEditing">Cancel</div>
        <div class="btn__green btn__confirm pointer" @click="submit">Submit</div>
      </div>
      <div v-if="deleting" class="dual--buttons">
        <div class="btn__red" @click="toggleDeleting">Cancel</div>
        <div class="btn__green btn__confirm" @click="deleteMessage">Confirm Delete</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["option", "user", "index"],
  data() {
    return {
      editing: false,
      deleting: false,
      response: {
        name: this.option.name,
        message: this.option.message
      },
      form: {
        _id: this.user._id,
        responses: this.user && this.user.responses ? this.user.responses : []
      }
    };
  },
  methods: {
    ...mapActions(["startSetIsSubmitting", "startUpdateSelf"]),
    async submit() {
      this.startSetIsSubmitting({ bool: true, heading: "Updating" });
      try {
        this.form.responses[this.index] = this.response;
        await this.startUpdateSelf(this.form);
        this.toggleEditing();
        this.startSetIsSubmitting({ bool: false });
      } catch (err) {
        this.startSetIsSubmitting({ bool: false });
      }
    },
    async deleteMessage() {
      this.startSetIsSubmitting({ bool: true, heading: "Deleting" });
      try {
        this.form.responses.splice(this.index, 1);
        await this.startUpdateSelf(this.form);
        this.toggleDeleting();
        this.startSetIsSubmitting({ bool: false });
      } catch (err) {
        this.startSetIsSubmitting({ bool: false });
      }
    },
    toggleEditing() {
      this.editing = !this.editing;
    },
    toggleDeleting() {
      this.deleting = !this.deleting;
    }
  }
};
</script>