<template>
  <div id="app">
    <simplebar class="main--overflow">
      <div id="content">
        <span ref="scrolltop"></span>
        <!-- <StickyButton /> -->
        <Header />
        <Notification v-if="getNotes && getNotes.notification" />
        <router-view></router-view>
        <Footer @scrollToTop="scrollToTop" />
      </div>
    </simplebar>
    <Loading v-if="getIsSubmitting" />
    <SubLoading v-if="getSubLoading" />
    <flash-message class="messages"></flash-message>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import StickyButton from "./components/Includes/Header/StickyButton";
import Header from "./components/Includes/Header/Index";
import Notification from "./components/Includes/Notification";
import Loading from "./components/Includes/Loading";
import SubLoading from "./components/Includes/SubLoading";
import Footer from "./components/Includes/Footer";
export default {
  name: "App",
  components: {
    Notification,
    // StickyButton,
    Header,
    Loading,
    SubLoading,
    Footer
  },
  computed: {
    ...mapGetters(["getIsSubmitting", "getNotes", "getSubLoading"])
  },
  watch: {
    $route() {
      this.$refs.scrolltop.scrollIntoView({
        block: "nearest",
        behavior: "smooth"
      });
    }
  },
  methods: {
    scrollToTop() {
      this.$refs.scrolltop.scrollIntoView({
        block: "nearest",
        behavior: "smooth"
      });
    },
    test() {
      console.log("testing");
    }
  }
};
</script>

<style lang="scss">
</style>
