<template>
  <section class="account-messages margin__md tile pos__rel">
    <download-excel
      :data="messagesData"
      name="messages-data.xls"
      type="csv"
      class="account-messages--download"
    >
      <font-awesome-icon icon="file-download" size="lg" />
    </download-excel>
    <label class="account-message--download" for="csvimport">
      <font-awesome-icon icon="file-upload" size="lg" />
      <input type="file" @change="readFile" id="csvimport" accept=".csv" />
    </label>
    <h2 class="text__center">Common Messages</h2>
    <p class="text__center p-tb-sm">
      These are messages which are available to use for thank you cards or comments on your orders.
      They serve as the entire message or as starting points to be edited.
    </p>
    <div class="account-messages__grid">
      <NewMessage :user="user" />
      <Message
        v-for="(option, index) in user.responses"
        :key="option._id"
        :option="option"
        :user="user"
        :index="index"
      />
    </div>
  </section>
</template>

<script>
import { mapActions } from "vuex";
import Message from "./Message";
import NewMessage from "./NewMessage";
export default {
  props: ["user"],
  components: {
    Message,
    NewMessage
  },
  data() {
    return {
      data: null,
      config: {
        delimiter: "",
        newline: "",
        quoteChar: '"',
        escapeChar: '"',
        header: true,
        dynamicTyping: true,
        preview: 1,
        encoding: "",
        delimitersToGuess: [",", "\t", "|", ";"],
        skipEmptyLines: "greedy"
      }
    };
  },
  computed: {
    messagesData() {
      const copyData = JSON.parse(JSON.stringify(this.user.responses));
      const messages = copyData.map(message => {
        return {
          name: message.name,
          message: message.message
        };
      });
      return messages;
    }
  },
  methods: {
    ...mapActions(["startSetIsSubmitting", "startUpdateUser"]),
    readFile(evt) {
      const file = evt.target.files[0];
      document.querySelector("#csvimport").value = "";
      const that = this;
      this.$papa.parse(file, {
        header: true,
        complete: function(results) {
          const data = results.data
            .filter(row => row.name)
            .map(row => {
              delete row[""];
              return row;
            });
          that.data = data;
          if (data.length <= 0) return;
          that.submit();
        }
      });
    },
    async submit() {
      this.startSetIsSubmitting({ bool: true, heading: "Adding" });
      try {
        const copyUser = JSON.parse(JSON.stringify(this.user));
        copyUser.responses.push(...this.data);
        const form = {
          _id: copyUser._id,
          responses: copyUser.responses
        };
        await this.startUpdateUser(form);
        this.startSetIsSubmitting({ bool: false });
        this.clear();
      } catch (err) {
        this.startSetIsSubmitting({ bool: false });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.account-message--download {
  @include pos-abs($pos: absolute, $top: 8px, $left: 8px, $z: 5);
  color: $peacock;
}
.account-messages--download {
  @include pos-abs($pos: absolute, $top: 8px, $left: null, $right: 8px, $z: 5);
  color: $sky;
}
</style>