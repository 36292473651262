import Vue from 'vue';
import VueRouter from 'vue-router';
import store from "../store";
import auth from "../firebase/auth";
import { createError } from "../libs/flash-messages";
import { checkLogin } from "./libs/helpers";

//components
// import Pages from "../components/Pages";

const loadView = view => {
   return () => import(/* webpackChunkName: "set-base" */ `../components/Pages/${view}/Index.vue`)
}

//routes
import neworder from "./new-order";
import product from "./product";
import bulk from "./bulk";
import myaccount from "./my-account";
import pastorder from "./past-order";
import admin from "./admin";

Vue.use(VueRouter);

const router = new VueRouter({
   mode: "history",
   routes: [
      {
         path: "/",
         name: "home",
         component: loadView("Home")
      },
      {
         path: "/login",
         name: "login",
         beforeEnter: async (to, from, next) => {
            if (store.getters.getIsLoggedIn) {
               return next("/")
            }
            try {
               await auth.signIn(auth.provider, false);
               return next(from.fullPath);
            } catch (err) {
               return next("/")
            }
         }
      },
      {
         path: "/contact-us",
         name: "contact",
         component: loadView("Contact"),
         beforeEnter: checkLogin
      },
      {
         path: "/help",
         name: "help",
         component: loadView("Help")
      },
      {
         path: "/about",
         name: "about",
         component: loadView("About")
      },
      ...product,
      ...neworder,
      ...bulk,
      ...myaccount,
      ...pastorder,
      ...admin,
      {
         path: "*",
         name: "not-found",
         beforeEnter: (to, from, next) => {
            createError("Not Found", "The requested page was not found.");
            next("/");
         }
      }
   ]
});

router.beforeEach(async (to, from, next) => {
   const user = await auth.checkUser();

   if (user) {
      if (!store.state.user.token) await store.dispatch("setUpUser", user);
   }
   // site setup
   try {
      if (!store.state.site.notes) await store.dispatch("startSetNotes");
      if (!store.state.categories.categories) await store.dispatch("startSetCategories");
      if (!store.state.products.products) await store.dispatch("startSetProducts");
      if (!store.state.site.homeTiles) await store.dispatch("startSetHomeTiles");
      if (store.state.user.isLoggedIn && !store.state.orders.orders) await store.dispatch("startSetHomeInfo");
   } catch (err) {
      console.log(err);
   }
   next();
});

export default router;