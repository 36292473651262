import Vue from 'vue';
import Editor from "@tinymce/tinymce-vue";

Vue.component("Editor", Editor);

export const tinyConfig = {
   apiKey: "ppky4gs9qh50j8ag8gl45uvjvrcel2jkkdmqv4be7t2wu545",
   config: {
      menubar: 'edit insert view format table tools',
      height: 250,
      plugins: ['link', 'lists', 'image', 'table', 'pagebreak', 'paste'],
      indent: false,
      paste_webkit_styles: 'none',
      paste_word_valid_elements: 'p,br,ul,ol,li,h1,h2,h3,h4,h5,h6,b,strong,i,em,a,img',
      paste_postprocess: function (plugin, args) {
         let html = args.node.innerHTML;
         const reStyle = /\sstyle=.[^>]*;./g;
         const reSpace = /&nbsp;/g;
         const reEmptyP = /<p>&nbsp;<\/p>/g;
         html = html.replace(reStyle, '').replace(reSpace, '').replace(reEmptyP, '');
         args.node.innerHTML = html;
      },
      toolbar: 'undo redo | formatselect | bold italic link | \
      alignleft aligncenter alignright alignjustify | \
      bullist numlist outdent indent | removeformat',
   }
}

export const basicConfig = {
   apiKey: "ppky4gs9qh50j8ag8gl45uvjvrcel2jkkdmqv4be7t2wu545",
   config: {
      menubar: '',
      height: 250,
      plugins: ['link', 'lists', 'image', 'table', 'pagebreak', 'paste'],
      indent: false,
      paste_webkit_styles: 'none',
      paste_word_valid_elements: 'p,br,ul,ol,li,h1,h2,h3,h4,h5,h6,b,strong,i,em,a,img',
      paste_postprocess: function (plugin, args) {
         let html = args.node.innerHTML;
         const reStyle = /\sstyle=.[^>]*;./g;
         const reSpace = /&nbsp;/g;
         const reEmptyP = /<p>&nbsp;<\/p>/g;
         html = html.replace(reStyle, '').replace(reSpace, '').replace(reEmptyP, '');
         args.node.innerHTML = html;
      },
      toolbar: 'undo redo | formatselect | bold italic link | \
      alignleft aligncenter alignright alignjustify | \
      bullist numlist outdent indent | removeformat',
   }
};

// export default {
//    tinyConfig,
//    basicConfig
// };