import api from "../../api";
import auth from "../../firebase/auth";
import { createSuccess, createError } from "../../libs/flash-messages";
import router from "../../router";

const removeCategory = (categories, id) => {
   return categories.filter(item => {
      return item !== id;
   });
}

const state = {
   filters: {
      name: "",
      priceMin: null,
      priceMax: null,
      respaStatus: [],
      categories: [],
      status: [],
      showFeatured: true,
      priceSort: "high-to-low"
   },
   products: null,
   activeProduct: null
};

const getters = {
   getProducts(state) {
      if (!state.products) return [];
      return state.products.sort((a, b) => {
         if (a.name < b.name) return -1;
         if (a.name > b.name) return 1;
         return 0;
      });
   },
   getFilteredProducts(state, getters, rootState) {
      if (!state.products) return [];
      const filters = state.filters;
      const route = router.currentRoute.fullPath;
      return state.products
         .filter(item => {
            if (route.includes("/new-order") || route.includes("/past-order") || route.includes("/batch-order")) {
               return item.status === "live";
            }
            if (route.includes("/admin")) {
               return item;
            }
            return item.status === "live" || item.status === "coming-soon";
         })
         .filter(item => {
            if (!filters.name) return item;
            return item.name.toLowerCase().includes(filters.name.toLowerCase()) || item.tags.join(",").includes(filters.name.toLowerCase());
         }).filter(item => {
            if (!filters.priceMin) return item;
            return item.loCost >= filters.priceMin;
         }).filter(item => {
            if (!filters.priceMax) return item;
            return item.loCost <= filters.priceMax;
         }).filter(item => {
            if (!filters.respaStatus || filters.respaStatus.length <= 0) return item;
            return filters.respaStatus.includes(item.respaStatus);
         }).filter(item => {
            if (!filters.categories || filters.categories.length <= 0) return item;
            return item.categories.map(category => {
               return filters.categories.includes(category);
            }).some(val => val);
         }).filter(item => {
            if (!filters.status || filters.status.length <= 0) return item;
            return filters.status.includes(item.status);
         }).filter(item => {
            const access = ["admin", "super-admin"];
            if (!rootState.user.userDetails || !access.includes(rootState.user.userDetails.permission)) {
               return item.status !== "test";
            }
            return item;
         }).filter(item => {
            if (filters.showFeatured) return item;
            return !item.isFeatured;
         }).sort((a, b) => {
            if (filters.priceSort === "high-to-low") {
               if (a.loCost > b.loCost) {
                  return -1;
               }
               if (a.loCost < b.loCost) {
                  return 1;
               }
               return 0;
            } else {
               if (a.loCost < b.loCost) {
                  return -1;
               }
               if (a.loCost > b.loCost) {
                  return 1;
               }
               return 0;
            }
         }).sort((a, b) => {
            if (a.isFeatured > b.isFeatured) {
               return -1;
            }
            if (a.isFeatured < b.isFeatured) {
               return 1;
            }
            return 0;
         });
   },
   getProductFilters(state) {
      return state.filters;
   },
   getActiveProduct(state) {
      return state.products.filter(product => {
         return product._id === state.activeProduct;
      })[0];
   },
   getLiveProducts(state) {
      if (!state.products) return [];
      return state.products
         .filter(item => {
            return item.status === "live";
         })
         .sort((a, b) => {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
         });
   },
   getRelatedProducts(state) {
      const limit = 6;
      if (!state.activeProduct) return null;
      const activeProduct = state.products.filter(product => product._id === state.activeProduct)[0];
      if (activeProduct.related && activeProduct.related.length > 0) {
         const toReturn = activeProduct.related.map(id => {
            const found = state.products.filter(product => product._id === id && product.status === "live");
            if (found && found.length > 0) {
               return found[0];
            }
            return null;
            // const toReturn = state.products.filter(product => activeProduct.related.includes(product._id) && product.status === "live")
         }).filter(item => item);
         if (toReturn.length > 0) return toReturn;
      }
      if (!activeProduct.tags || activeProduct.tags.length <= 0) {
         return state.products.filter(product => product.isFeatured).slice(0, limit);
      }
      return state.products.filter(product => {
         return product.categories.map(item => {
            return activeProduct.categories.includes(item);
         }).some(item => item);
      }).slice(0, limit);
   },
   getFeaturedProducts(state) {
      const limit = 12;
      if (!state.products) return null;
      return state.products.filter(product => product.isFeatured).slice(0, limit);
   },
   getInventoriedProducts(state) {
      if (!state.products) return null;
      return state.products.filter(product => product.isInventoried);
   },
   getRecentProducts(state) {
      if (!state.products) return null;
      return state.products
         .filter(item => {
            return item.status;
         })
         .sort((a, b) => {
            if (a.createdAt < b.createdAt) return 1;
            if (a.createdAt > b.createdAt) return -1;
            return 0;
         }).slice(0, 12);

   },
   getSeasonalProducts(state) {
      if (!state.products) return null;
      return state.products
         .filter(item => {
            return item.status;
         })
         .sort((a, b) => {
            if (a.createdAt < b.createdAt) return 1;
            if (a.createdAt > b.createdAt) return -1;
            return 0;
         }).slice(0, 12);
   }
};

const actions = {
   async startSetProducts({ commit }) {
      try {
         const products = await api.axiosGet("/public/products");
         commit("SET_PRODUCTS", products);
         return Promise.resolve("succes");
      } catch (err) {
         createError("Products failed", err);
         return Promise.reject(err);
      }
   },
   async startCreateProduct({ commit }, form) {
      try {
         await auth.refreshFlow(auth.provider);
         const product = await api.axiosPost(`/products`, form);
         await this._vm.$socket.emit("CREATE_PRODUCT", product);
         commit("CREATE_PRODUCT", product);
         createSuccess(`Product: ${form.name} created`);
         router.push(`/admin/products/${product._id}`);
         return Promise.resolve(product);
      } catch (err) {
         createError("Failed to create", err);
         return Promise.reject(err);
      }
   },
   async startUpdateProduct({ commit }, form) {
      const id = form._id;
      try {
         await auth.refreshFlow(auth.provider);
         const product = await api.axiosPut(`/products/${id}`, form);
         this._vm.$socket.emit("UPDATE_PRODUCT", product);
         commit("COMPLETE_SOCKET");
         createSuccess(`Product: ${product.name} updated`);
         return Promise.resolve("success");
      } catch (err) {
         createError("Failed to update", err);
         return Promise.reject(err);
      }
   },
   async startDeleteProduct({ commit }, form) {
      const id = form._id;
      try {
         await auth.refreshFlow(auth.provider);
         await api.axiosDelete(`/products/${id}`);
         router.push("/admin/products");
         this._vm.$socket.emit("DELETE_PRODUCT", id);
         commit("COMPLETE_SOCKET");
         createSuccess(`Product: ${form.name} deleted`);
         commit("SET_ACTIVE_PRODUCT", null);
         return Promise.resolve("success");
      } catch (err) {
         createError("Failed to delete", err);
         return Promise.reject(err);
      }
   },
   startSetActiveProduct({ commit, state }, id) {
      // check if ID exists in the products loaded
      const product = state.products.filter(item => {
         return item._id === id;
      })[0];
      if (product) {
         commit("SET_ACTIVE_PRODUCT", id);
         return Promise.resolve("success");
      } else {
         createError("Error", "No product found by the id provided.");
         return Promise.reject("no product found");
      }
   },
   startUnsetActiveProduct({ commit }, val) {
      commit("SET_ACTIVE_PRODUCT", val);
   },
   async startDeleteImage({ commit }, file) {
      try {
         await auth.refreshFlow(auth.provider);
         await api.axiosDelete(`/products/images/${file}`);
         commit("COMPLETE_SOCKET");
         return Promise.resolve("success");
      } catch (err) {
         createError("Failed to delete", err);
         return Promise.reject(err);
      }
   },
   async startCreateProductCust({ commit, state }, form) {
      const id = form._id;
      // create a copy to avoid editing component form
      const copyForm = JSON.parse(JSON.stringify(form));
      try {
         await auth.refreshFlow(auth.provider);
         const product = state.products.filter(item => item._id === id)[0];
         if (!product) throw new Error("Couldn't find product to update");
         product.customizations.push(copyForm.customizations);
         copyForm.customizations = product.customizations;
         const newProduct = await api.axiosPut(`/products/${id}`, copyForm);
         this._vm.$socket.emit("UPDATE_PRODUCT", newProduct)
         commit("COMPLETE_SOCKET");
         createSuccess(`Product: ${product.name} updated`);
         return Promise.resolve("success");
      } catch (err) {
         createError("Failed to update", err);
         return Promise.reject(err);
      }
   },
   async startUpdateProductCust({ commit, state }, form) {
      const id = form._id;
      const cust_id = form.customizations._id;
      // create a copy to avoid editing component form
      const copyForm = JSON.parse(JSON.stringify(form));
      try {
         await auth.refreshFlow(auth.provider);
         const product = state.products.filter(item => item._id === id)[0];
         if (!product) throw new Error("Couldn't find product to update");
         const custIndex = product.customizations.findIndex(item => item._id === cust_id);
         if (custIndex < 0) throw new Error("Couldn't find a customization to update");
         product.customizations.splice(custIndex, 1, copyForm.customizations);
         copyForm.customizations = product.customizations;
         const newProduct = await api.axiosPut(`/products/${id}`, copyForm);
         this._vm.$socket.emit("UPDATE_PRODUCT", newProduct)
         commit("COMPLETE_SOCKET");
         createSuccess(`Product: ${product.name} updated`);
         return Promise.resolve("success");
      } catch (err) {
         createError("Failed to update", err);
         return Promise.reject(err);
      }
   },
   async startDeleteProductCust({ commit, state }, form) {
      const id = form._id;
      const cust_id = form.customizations._id;
      const copyForm = JSON.parse(JSON.stringify(form));
      try {
         await auth.refreshFlow(auth.provider);
         const product = state.products.filter(item => item._id === id)[0];
         if (!product) throw new Error("Couldn't find product to update");
         const custIndex = product.customizations.findIndex(item => item._id === cust_id);
         if (custIndex < 0) throw new Error("Couldn't find a customization to delete");
         product.customizations.splice(custIndex, 1);
         copyForm.customizations = product.customizations;
         const newProduct = await api.axiosPut(`/products/${id}`, copyForm);
         this._vm.$socket.emit("UPDATE_PRODUCT", newProduct)
         commit("COMPLETE_SOCKET");
         createSuccess(`Product: ${product.name} updated`);
         return Promise.resolve("success");
      } catch (err) {
         createError("Failed to delete", err);
         return Promise.reject(err);
      }
   },
   startSetProductFilters({ commit }, form) {
      commit("SET_PRODUCT_FILTERS", form);
   },
   startUnsetProductFilters({ commit }) {
      const filters = {
         name: "",
         priceMin: null,
         priceMax: null,
         respaStatus: [],
         categories: [],
         status: [],
         showFeatured: true,
         priceSort: "high-to-low"
      }
      commit("SET_PRODUCT_FILTERS", filters);
   },
   startEraseProductFilter({ commit }, key) {
      const filters = {
         name: "",
         priceMin: null,
         priceMax: null,
         respaStatus: [],
         categories: [],
         status: [],
         showFeatured: true,
         priceSort: "high-to-low"
      }
      let info = {};
      info.key = key;
      info.value = filters[key];
      commit("ERASE_PRODUCT_FILTER", info);
   }
};

const mutations = {
   COMPLETE_SOCKET() {
      return;
   },
   SET_PRODUCTS(state, products) {
      return state.products = products;
   },
   CREATE_PRODUCT(state, product) {
      if (state.products) {
         return state.products.push(product);
      }
      return state.products = [product];
   },
   SOCKET_CREATE_PRODUCT(state, product) {
      const index = state.products.findIndex(item => {
         return item._id === product._id;
      });
      if (index < 0) {
         if (state.products) {
            return state.products.push(product);
         }
         return state.products = [product];
      }
      return state.products.splice(index, 1, product);
   },
   SOCKET_UPDATE_PRODUCT(state, product) {
      const index = state.products.findIndex(item => {
         return item._id === product._id;
      });
      if (index >= 0) {
         return state.products.splice(index, 1, product);
      }
      return state.products.push(product);
   },
   SOCKET_DELETE_PRODUCT(state, id) {
      const index = state.products.findIndex(item => {
         return item._id === id;
      });
      if (index >= 0) {
         return state.products.splice(index, 1);
      }
   },
   SET_ACTIVE_PRODUCT(state, product) {
      return state.activeProduct = product;
   },
   SET_PRODUCT_FILTERS(state, form) {
      state.filters.name = form.name;
      state.filters.priceMin = form.priceMin;
      state.filters.priceMax = form.priceMax;
      state.filters.respaStatus = form.respaStatus;
      state.filters.categories = form.categories;
      state.filters.priceSort = form.priceSort;
      state.filters.status = form.status;
      state.filters.showFeatured = form.showFeatured;
   },
   ERASE_PRODUCT_FILTER(state, info) {
      state.filters[info.key] = info.value;
   },
   SOCKET_DELETE_PRODUCT_CATEGORY(state, id) {
      const products = JSON.parse(JSON.stringify(state.products));
      return state.products = products.map(item => {
         const categories = removeCategory(item.categories, id);
         item.categories = categories;
         return item;
      });
   }
};

export default {
   state,
   getters,
   actions,
   mutations
}