<template>
  <li class="order-tile tile pos__rel">
    <div class="order-tile--status flex__center">
      <span class="text__bold text__white">{{ order.status }}</span>
    </div>
    <div class="order-row">
      <span>Created:</span>
      <span class="text__overflow">{{ cleanDate }}</span>
    </div>
    <div class="order-row">
      <span>Order Type:</span>
      <span>{{ order.bulkOrder ? 'Batch' : 'Normal' }}</span>
    </div>
    <div class="order-row">
      <span>Recipient:</span>
      <span class="text__overflow">{{ order.recipientName }}</span>
    </div>
    <div class="order-row">
      <span>Total Cost:</span>
      <span class="text__pine text__bold">${{ order.totalCost.toFixed(2) }}</span>
    </div>
    <ul>
      <simplebar class="small--overflow" data-simplebar-auto-hide="false">
        <li v-for="orderItem in order.orderItems" :key="orderItem._id" class="flex__between">
          <span>{{ orderItem.quantity }} - {{ orderItem.productName }}</span>
          <span>${{ (orderItem.quantity * orderItem.loCost).toFixed(2) }}</span>
        </li>
      </simplebar>
    </ul>
    <div class="order-row">
      <span>Balance Due:</span>
      <span class="text__green text__bold">${{ order.balanceDue.toFixed(2) }}</span>
    </div>
    <div class="order-comments">
      <span class="text__bold">Comments:</span>
      <simplebar class="small--overflow" data-simplebar-auto-hide="false">
        <span class="pre-wrap">{{ order.comments }}</span>
      </simplebar>
    </div>
    <div class="flex__right text__bold">
      <router-link :to="orderLink" class="link__none">
        View
        <font-awesome-icon icon="arrow-right" size="sm" class="ml-10" />
      </router-link>
    </div>
  </li>
</template>

<script>
import moment from "moment";
export default {
  props: ["order"],
  computed: {
    orderReference() {
      return this.order.status === "Started" ? "new-order" : "past-order";
    },
    cleanDate() {
      return moment(this.order.createdAt).format("MM-DD-YYYY hh:mm a");
    },
    orderLink() {
      return this.order.bulkOrder && this.order.status === "Started"
        ? `/batch-order/${this.order.bulkOrder}/batch-items`
        : `/${this.orderReference}/${this.order._id}/order-confirm`;
    }
  }
};
</script>

<style scoped lang="scss">
</style>